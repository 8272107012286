<template>
  <div>
    <b-container class="main-cont mb-3">
      <b-form class="input__form" v-if="secondQuestionShow">
        <div class="row my-4 no-gutters">
          <div class="col-md-7 col-12">
            <!-- <BackButton @back="goBack" /> -->
            <p class="mb-0 vraag">Vraag {{ step }} van 5</p>
            <h2 class="title">Betreft het een koopwoning?</h2>
            <div class="row my-4 mx-0">
              <b-form-group>
                <b-form-radio
                  v-for="(item, index) of options"
                  v-model="selected"
                  :key="index"
                  :name="'dateOption'"
                  :value="item.value"
                  @change="updateDiv(item.value)"
                >
                  {{ item.title }}
                </b-form-radio>
              </b-form-group>

              <p v-if="setDisabled" class="invalid-text mb-0">
                Selecteer er ten minste één
              </p>
            </div>

            <b-button class="input__form-btn" @click="goToNextStep">
              Volgende
              <span
                ><svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z"
                    fill="white"
                  />
                </svg>
              </span>
            </b-button>
          </div>
          <div class="col-md-5 col-12 p-0">
            <p class="input__form-label mb-1">
              {{
                this.address.street +
                " " +
                this.address.streetNumber +
                (this.address?.premise ? this.address?.premise : "") +
                ", " +
                this.address.postalCode +
                " in " +
                this.address.settlement
              }}
            </p>
            <GoogleMapComponent />
          </div>
        </div>
      </b-form>
      <div v-else>
        <h2 class="title text-start fw-bold my-5 pb-5">
          Helaas kunnen wij jou niet helpen, omdat je geen eigenaar bent van de
          woning.
        </h2>
      </div>
    </b-container>
    <div class="d-flex justify-content-end">
      <div
        class="mx-3 cursor-pointer"
        @click="goToNextStepWithoutValue"
        v-if="secondQuestionShow == false"
      >
        <p class="input__form-back_label d-inline-block mx-2">
          Ik ben wel eigenaar van mijn woning
        </p>
        <svg
          class="nextArrow"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.1875 9H2.8125"
            stroke="#62cfb3"
            stroke-width="1.92"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.875 3.9375L2.8125 9L7.875 14.0625"
            stroke="#62cfb3"
            stroke-width="1.92"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import loader from "sass-loader";
import GoogleMapComponent from "../../GoogleMapComponent.vue";
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../BackButton.vue";

export default {
  name: "Question2",
  components: { GoogleMapComponent, BackButton },
  props: {
    step: {
      type: Number,
    },
    secondQuestion: {
      type: String,
    },
    secondQuestionShow: {
      type: Boolean,
    },
    setAddress: {
      type: Boolean,
    },
  },
  data() {
    return {
      // loader: false,
      // zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      // houseRegex: /^[0-9]+$/,
      // validationCheck: true,
      selectedValue: "",
      selected: -1,
      setDisabled: false,
      options: [
        { title: "Ja", value: "3830" },
        { title: "Nee", value: "123" },
      ],
    };
  },
  computed: {
    ...mapGetters(["lat", "lng", "getErrorResponses", "address"]),
  },
  mounted() {
    if (this.secondQuestion) {
      this.selected = this.secondQuestion;
    }
  },
  methods: {
    ...mapActions(["getLocation"]),

    goToNextStep() {
      if (this.selectedValue) {
        this.$emit("handleChange", "secondQuestion", this.selectedValue);
        if (this.selectedValue == "3830") {
          setTimeout(() => {
            this.$emit("nextStep", this.step + 1);
          }, 500);
        } else if (this.selectedValue == "123") {
          setTimeout(() => {
            this.$emit("secondQuestionShowNext", false);
            this.secondQuestionShow = false;
          }, 500);
        }
      } else {
        if (!this.selectedValue) {
          this.setDisabled = true;
          setTimeout(() => {
            this.setDisabled = false;
          }, 2500);
        }
      }
    },
    goToNextStepWithoutValue() {
      this.$emit("handleChange", "secondQuestion", "3830");
      setTimeout(() => {
        this.$emit("secondQuestionShowNext", true);
        this.$emit("nextStep", this.step + 1);
      }, 500);
      // }
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(value) {
      this.selectedValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.nextArrow {
  transform: rotate(180deg);
}
.invalid-text {
  text-align: left;
  width: 100%;
  // margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  font-size: 13px;
}
</style>
