<template>
  <b-container
    class="main-cont p-0"
    :class="
      !details.form.leadResponse
        ? ''
        : 'd-flex flex-column justify-content-center'
    "
  >
    <b-form
      class="input__form position-relative"
      v-if="!details.form.leadResponse"
    >
      <img src="../../../assets/offer.svg" class="offer_badge" />
      <h2 class="title mb-3">
        <!-- Bedankt! Ontvang nu gratis en vrijblijvend een offerte op maat en
        profiteer nu ook van onze tijdelijke 3 maanden gratis actie! -->
        Bedankt! Ontvang nu gratis een berekening voor jouw woning. ‌
      </h2>
      <b-form-group v-slot="{ ariaDescribedby }" class="mt-4 mb-0">
        <b-form-radio-group
          id="radio-group-1"
          v-model="details.form.gender"
          :options="genderOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
          class="d-flex gender"
        >
        </b-form-radio-group>
        <b-form-invalid-feedback>
          Voer een geldige e-mailadres in
        </b-form-invalid-feedback>
      </b-form-group>
      <p v-if="genderNotSelected" class="invalid-text mb-0">
        Selecteer er ten minste één
      </p>
      <div class="row my-2 mx-0">
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Postcode"
            class="input__form-label"
            label="Voornaam"
            label-for="Voornaam"
          >
            <b-form-input
              v-model="details.form.firstName"
              class="input__form-field"
              :class="{
                'is-valid': fnameCheck && details.form.firstName !== '',
                'is-invalid': !fnameCheck,
              }"
              type="text"
              required
              @input="validate('fname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!fnameCheck">
              Voornaam niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Postcode"
            class="input__form-label"
            label="Achternaam"
            label-for="Achternaam"
          >
            <b-form-input
              v-model="details.form.lastName"
              class="input__form-field"
              :class="{
                'is-valid': lNameCheck && details.form.lastName !== '',
                'is-invalid': !lNameCheck,
              }"
              type="text"
              required
              @input="validate('lname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!lNameCheck">
              Achternaam niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Postcode"
            class="input__form-label"
            label="Emailadres"
            label-for="Emailadres"
          >
            <b-form-input
              v-model="details.form.email"
              class="input__form-field"
              :class="{
                'is-valid': emailCheck && details.form.email !== '',
                'is-invalid': !emailCheck,
              }"
              type="email"
              required
              @input="validate('email')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!emailCheck"
              >E-mailadres niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Telefoonnummer"
            class="input__form-label"
            label="Telefoonnummer"
            label-for="Telefoonnummer"
          >
            <b-form-input
              v-model="details.form.phone_number"
              class="input__form-field"
              :class="{
                'is-valid': phoneCheck && details.form.phone_number !== '',
                'is-invalid': !phoneCheck,
              }"
              type="email"
              required
              @input="validate('phone')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!phoneCheck"
              >Telefoonnummer niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- <div class="col-lg-6 mb-2 p-0">
          <b-form-group id="Telefoonnummer" class="input__form-label" label="Postcode" label-for="Postcode">
            <b-form-input v-model="details.firstQuestion.zip" class="input__form-field text-uppercase"
              :class="{ 'is-valid': zipCheck && details.firstQuestion.zip !== '', 'is-invalid': !zipCheck }"
              type="email" required @input="validate('zip')"></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback v-if="!houseCheck">Postcode niet
            correct</b-form-invalid-feedback>

        </div>
        <div class="col-lg-6 mb-2 p-0">

          <b-form-group id="Huisnummer" class="input__form-label" label="Huisnummer" label-for="Huisnummer">
            <b-form-input v-model="details.firstQuestion.house_number" class="input__form-field "
              :class="{ 'is-valid': houseCheck && details.firstQuestion.house_number !== '', 'is-invalid': !houseCheck }"
              type="email" required @input="validate('house')"></b-form-input>
            <b-form-invalid-feedback v-if="!houseCheck">Huisnummer niet
              correct</b-form-invalid-feedback>
          </b-form-group>

        </div> -->
        <div class="mt-0 mx-0 px-0">
          <p class="input__form-label new-label m-0 fst-italic small">
            Gebruikt Hallostroom alleen indien meer informatie nodig is voor de
            berekening
          </p>
        </div>
        <div class="mt-4 mx-0 px-0">
          <b-button
            class="input__form-btn"
            @click="submitForm"
            :disabled="getLoader"
          >
            <b-spinner small v-if="getLoader"></b-spinner>
            Afronden
            <span
              ><svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z"
                  fill="white"
                />
              </svg>
            </span>
          </b-button>
        </div>
        <div class="mt-4 mx-0 px-0 d-none">
          <b-form-checkbox id="checkbox-1" v-model="bootKey" name="checkbox-1">
          </b-form-checkbox>
        </div>
        <div class="mt-4 mx-0 px-0">
          <p class="input__form-label result-label">
            Met jouw gegevens gaan wij zorgvuldig om. Jouw gegevens worden
            uitsluitend door Hallostroom gebruikt om een offerte op maat te
            kunnen opstellen. Dit is volledig gratis en vrijblijvend, je zit
            nergens aan vast!
          </p>
        </div>
      </div>
    </b-form>
    <b-form
      class="input__form d-flex justify-content-center align-items-center"
      v-else
    >
      <!-- <h2 class="title text-center mb-0">Bedankt!</h2> -->
      <img
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.mijnconsument.nl/m/5681/4ba53c694cda/?event=6635&unique_conversion_id=${profileId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
      <p class="m-0 title text-center">
        Bedankt voor je aanvraag. Wij nemen zo snel mogelijk contact met je op!
      </p>
      <!-- 
      <p class="mt-4">
        <strong
          >Jij ontvangt z.s.m. 5 offertes van erkende installateurs.
        </strong>
      </p> -->
    </b-form>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ResultForm",
  props: {
    step: {
      type: Number,
    },
    details: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["address", "getLoader", "profileId"]),
  },
  watch: {
    bootKey(newVal) {
      console.log("Checkbox value changed:", newVal);
    },
    "details.form.leadResponse": function (newVal, oldVal) {
      if (newVal) {
        this.setupFacebookPixel();
      }
    },
  },
  data() {
    return {
      phoneRegex: /^(0|\+?31|00\s?31)([-\s]?\d[-\s]?){7,8}\d$/,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
      // zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      // houseRegex: /^[0-9]+$/,
      fnameCheck: true,
      lNameCheck: true,
      phoneCheck: true,
      emailCheck: true,
      zipCheck: true,
      houseCheck: true,
      bootKey: false,
      genderNotSelected: false,

      genderOptions: [
        { text: "Mevrouw", value: "female" },
        { text: "Meneer", value: "male" },
      ],
    };
  },

  methods: {
    ...mapActions(["postLead"]),
    validate(key) {
      if (key == "fname") {
        this.fnameCheck = this.nameRegex.test(this.details.form.firstName);
      } else if (key == "lname") {
        this.lNameCheck = this.nameRegex.test(this.details.form.lastName);
      } else if (key == "email") {
        this.emailCheck = this.emailRegex.test(this.details.form.email);
      } else if (key == "phone") {
        this.phoneCheck = this.phoneRegex.test(this.details.form.phone_number);
      } else if (key == "zip") {
        this.zipCheck = this.zipRegex.test(this.details.firstQuestion.zip);
      } else {
        this.houseCheck = this.houseRegex.test(
          this.details.firstQuestion.house_number
        );
      }
    },
    submitForm() {
      // console.log("submitted form", this.details) /^0*(\d+)$/
      const phoneNumberWithoutPunctuation = this.details.form.phone_number
        .replace(/[\s-]/g, "")
        .replace(/^00(\d+)$/, "$1");
      console.log(
        "phoneNumberWithoutPunctuation: ",
        phoneNumberWithoutPunctuation
      );

      let answers = [
        // "5005",
        this.details.secondQuestion,
        // this.details.thirdQuestion,
        // this.details.fourthQuestion,
        // this.details.fifthQuestion,
      ];
      let payload = {
        bootkey: this.bootKey,
        language: "nl_NL",
        site_custom_name: "hallostroom_3maandengratis",
        site_custom_url: "https://hallostroom.mijnconsument.nl",
        firstname: this.details.form.firstName,
        lastname: this.details.form.lastName,
        email: this.details.form.email,
        gender: this.details.form.gender,
        street: this.address?.street || "",
        city: this.address?.settlement || "",
        house_number: this.details?.firstQuestion?.house_number,
        zip: this.details.firstQuestion.zip,
        phone_number: phoneNumberWithoutPunctuation,
        answers: answers,
        site_subid: this.$route.query.clickid || "",
        publisher_id: this.$route.query.site || "",
      };
      if (this.details.form.gender) {
        this.postLead({
          payload,
          onSuccess: () => {
            this.details.form.leadResponse = true;
            // this.$emit("nextStep", this.step + 1);
          },
          onError: (res) => {
            let { response } = res;
            console.log(res, "response error");
            if (response.status == 409) {
              this.details.form.leadResponse = true;
              // this.$emit("nextStep", this.step + 1); // duplicate
            } else if (response.data.code == 400) {
              if (
                response.data.error ==
                "Bad Request - Validation error for 'phone_number'"
              ) {
                this.phoneCheck = false;
              } else if (
                response.data.error ==
                "Bad Request - Validation error for 'house_number'"
              ) {
                this.houseCheck = false;
                this.$emit("setAddress", "house");
              } else if (
                response.data.error ==
                "Bad Request - Validation error for 'lastname'"
              ) {
                this.lNameCheck = false;
              } else if (
                response.data.error ==
                "Bad Request - Validation error for 'firstname'"
              ) {
                this.fnameCheck = false;
              } else if (
                response.data.error ==
                "Bad Request - Validation error for 'email'"
              ) {
                this.emailCheck = false;
              } else if (
                response.data.error ==
                "Bad Request - Validation error for 'zip'"
              ) {
                this.zipCheck = false;
                this.$emit("setAddress", "post");
              } else {
                alert(response.data.error);
              }
            }
          },
        });
      } else {
        if (!this.details.form.gender) {
          this.genderNotSelected = true;
          setTimeout(() => {
            this.genderNotSelected = false;
          }, 2500);
        }
      }
    },
    setupFacebookPixel() {
      let useScript = document.createElement("script");
      useScript.setAttribute(
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";

          n.queue = [];
          t = b.createElement(e);
          t.async = !0;

          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        ),
        fbq("init", "969816563708997"),
        fbq("track", "PageView"),
        fbq("track", "Lead")
      ),
        document.head.appendChild(useScript);
    },
  },
  created() {
    document.title = "Hallostroom 3 maanden gratis";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=1139857983706927&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>
<style lang="scss" scoped>
.main-cont {
  min-height: 50vh;
  padding: 0 !important;
}

.input__form {
  text-align: center;
  padding: 1rem 2rem;

  .result-label {
    color: #000b29 !important;
    font-size: 14px;
  }
  .new-label {
    color: #000b29 !important;
    font-size: 12px;
  }

  .title {
    text-align: left;
    font-size: 30px;
    line-height: 35px;
  }

  .row {
    text-align: start;
  }

  @media (max-width: 1399.98px) {
    .title {
      text-align: start;
      font-size: 25px;
      line-height: 35px;
    }
  }
  @media (max-width: 991.98px) {
    .title {
      text-align: start;
      font-size: 23px;
      line-height: 35px;
    }
  }

  @media (max-width: 450px) {
    padding: 0;

    &-field {
      width: 100% !important;
    }
  }

  @media (max-width: 390px) {
    .title {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .offer_badge {
    position: absolute;
    width: 300px;
    right: -270px;
    top: -10px;
    @media (max-width: 991.98px) {
      width: 250px;
      top: auto;
      right: 30px;
      bottom: -130px;
    }
    @media (max-width: 575.98px) {
      right: -30px;
    }
  }
}
.input__form {
  @media (max-width: 991.98px) {
    padding-bottom: 100px;
  }
}
.gender {
  .custom-radio {
    margin-left: 20px;
  }
}
.invalid-text {
  text-align: left;
  width: 100%;
  // margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  font-size: 13px;
}
</style>
