import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  getLoader: false,
  id: "",
};

const getters = {
  getLoader: (state) => state.getLoader,
  profileId: (state) => state.id,
};

const actions = {
  async postLead({ commit }, { payload, onSuccess, onError }) {
    commit("setGetLoader", true);
    let { bootkey, ...restPayload } = payload;
    try {
      if (!bootkey) {
        const response = await leads.postLead(restPayload);
        const responseId = response.data.profile.id;
        commit("id", responseId);
        commit("setGetLoader", false);
        onSuccess();
      } else {
        commit("setGetLoader", false);
        onSuccess();
      }
    } catch (error) {
      commit("setGetLoader", false);
      onError(error);
      // alert(error.response.data.error);
    }
  },
};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
