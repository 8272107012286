<template>
  <div class="hero-section">
    <Form
      @show-second-form="toggleForm"
      v-if="!showQuestions"
      :showQuestions="showQuestions"
    />
    <Questions v-else />
  </div>
</template>

<script>
import Form from "./FormSteps/Form.vue";
import Questions from "./FormSteps/Questions/Questions.vue";
// import SecondForm from "./SecondForm.vue";

export default {
  name: "HeroSection",
  components: {
    Form,
    Questions,
  },
  props: {
    showQuestions: Boolean,
  },
  methods: {
    toggleForm() {
      this.$emit("update", !this.showQuestions);
    },
  },
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "969816563708997"),
      fbq("track", "PageView")
    ),
      document.head.appendChild(useScript);
  },

  created() {
    document.title = "Hallostroom 3 maanden gratis";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=1139857983706927&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  // background: url("../assets/hero_img.png") no-repeat;
  background: linear-gradient(231.87deg, #079ed0 28.01%, #0aa9d9 94.57%);
  background-size: cover;
  background-position: right;
  padding-top: 105px;
  margin-top: -105px;
  @media (max-width: 991.98px) {
    padding-top: 90px;
  }
  @media (max-width: 767.98px) {
    padding-top: 60px;
  }
}
</style>
