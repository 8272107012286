<template>
  <div>
    <b-container class="py-4">
      <hr class="d-md-none d-flex border-white border-1 opacity-100" />
      <b-row class="my-2 my-sm-5">
        <b-col class="col-12 col-lg-8 col-xl-7 col-xxl-6">
          <div class="form p-0 bg-transparent border-0">
            <div class="form__top p-0">
              <h1 class="form__heading d-md-block d-none">
                Doorbraak!<br />
                Zonnepanelen <br />
                <!-- vanaf 1 euro per dag -->
                zonder investering
                <!-- <span> 40% Extra Voordeel </span> -->
              </h1>
              <h1 class="text-center form__heading d-md-none d-block">
                <!-- Doorbraak! Zonnepanelen al vanaf €1 p/d -->
                Doorbraak! Zonnepanelen zonder investering
              </h1>
              <p class="text-md-start text-center form__sub_heading mb-0">
                <!-- Bij Hallostroom huur je zonnepanelen zonder zorgen. -->
                Je profiteert maximaal, omdat zonnepanelen bij Hallostroom nu
                goedkoper zijn dan ooit!
              </p>
              <div class="my-md-4 mt-4 mb-2">
                <div
                  class="text-start mb-1 points__div"
                  v-for="(item, index) of points"
                  :key="index"
                >
                  <p class="mb-2">
                    <span>
                      <svg
                        width="21"
                        height="22"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12.77" r="12" fill="#62CFB3" />
                        <path
                          d="M7.14935 12.9388L10.6577 16.0092C11.1119 16.4067 11.812 16.3143 12.1475 15.8127L16.8508 8.78101"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg> </span
                    ><span class="mx-2">{{ item }}</span>
                  </p>
                </div>
              </div>
            </div>
            <img
              src="../../assets/offer.svg"
              class="offer_img d-lg-flex d-none"
            />
            <div
              class="text-start px-0 py-0 py-lg-3 profit__div d-lg-block d-flex align-items-center"
            >
              <!-- <div class="position-relative d-lg-none d-block offer"> -->
              <img
                src="../../assets/offer_sm.svg"
                class="offer_img d-lg-none d-flex"
              />
              <!-- </div> -->
              <p class="mb-0">
                <!-- Nu goedkoper dan ooit! Check hier gratis of jouw dak in
                aanmerking komt. -->
                <span class="fw-normal">Met onze </span>gratis woningcheck
                <span class="fw-normal"
                  >ontdek je in 1 minuut of jouw woning in aanmerking komt en
                  ontvang je een berekening op maat!</span
                >
              </p>
            </div>
            <div class="pb-0 pb-sm-4 px-0 pt-lg-4 text-center text-sm-start">
              <b-button
                class="btnForm mb-2 d-flex justify-content-center align-items-center mx-lg-0 mx-auto"
                @click="showSecondForm"
              >
                <!-- <span> Start Gratis Dakcheck</span> -->
                <span> Start Gratis Woningcheck</span>
                <span class="mx-2">
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.469757 1.53029L5.93951 7.00004L0.469757 12.4698L1.53026 13.5303L8.06051 7.00004L1.53026 0.469788L0.469757 1.53029Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </b-button>
              <div
                class="d-flex justify-content-lg-start justify-content-center"
              >
                <p
                  class="mb-0 d-flex align-items-lg-end align-items-center p-lg-0 py-2 px-3 sm_badge"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.54159 15.1449H6.80913C8.13863 15.1449 9.39636 14.5809 10.3076 13.6131C10.7266 13.168 11.2043 12.757 11.7342 12.3946C11.8401 12.3221 11.9517 12.2662 12.0597 12.1988L14.038 10.8121L16.515 9.06863L16.7064 8.86493C17.3929 8.13277 17.888 7.23894 18.1505 6.26248L18.7202 4.13268C18.8297 3.71512 19.2052 3.43168 19.6271 3.44038C22.3311 3.64118 22.7841 8.40606 20.6869 13.2355C20.6608 13.2956 20.6224 13.392 20.655 13.495C20.6971 13.6255 20.9022 13.6929 21.2785 13.7262C21.6127 13.7559 26.6718 14.2068 27.765 14.2068C28.3877 14.2068 26.0259 27.6077 26.0259 28.6805C26.0259 29.8817 24.9835 31.0633 23.7823 31.0633C23.7802 31.0633 13.6175 31.2968 11.6726 30.5218C10.9919 30.2507 10.3895 29.7766 9.89075 29.1901C8.97228 28.1107 7.64206 27.4677 6.22485 27.4677H4.54159C4.03632 27.4677 3.62747 24.7087 3.62747 21.306C3.62747 17.9032 4.03632 15.1449 4.54159 15.1449Z"
                      fill="url(#paint0_radial_57_52)"
                    />
                    <g opacity="0.87">
                      <path
                        opacity="0.091"
                        d="M17.5742 16.8311C17.4821 17.3654 17.5626 17.8728 17.7692 18.3092C18.0439 18.8906 18.0106 19.5815 17.5677 20.0476C17.1515 20.4847 16.8949 21.0741 16.8935 21.7265C16.8935 21.7497 16.8935 21.7729 16.8942 21.7961C16.9029 22.1433 16.9841 22.4724 17.1226 22.7704C17.3792 23.322 17.3038 23.9803 16.9051 24.4406C16.8369 24.5189 16.7746 24.6022 16.7166 24.69C16.4636 25.0771 16.3172 25.5446 16.3193 26.0419C16.3201 26.2507 16.3476 26.453 16.3991 26.6451C16.4368 26.785 16.4868 26.9213 16.5484 27.051C16.6514 27.2685 16.7007 27.4939 16.6949 27.7042C16.6905 27.8607 16.6579 28.0311 16.5665 28.1906C16.5165 28.2776 16.4629 28.3653 16.4013 28.4479C16.1649 28.7647 16.0453 29.1641 16.0953 29.6078C16.1889 30.4328 17.0435 31.0765 17.8736 31.0968C17.8757 31.0968 17.9142 31.0968 17.9163 31.0968H20.3238C21.5039 31.0968 22.3188 30.4364 22.3847 29.5375C22.4231 29.0192 22.226 28.5458 21.8903 28.2131C22.6841 27.8035 23.2322 26.9858 23.2322 26.0311C23.2322 25.2221 22.8392 24.5087 22.2368 24.0593C22.2209 24.0448 22.226 24.0187 22.2484 24.01C23.1495 23.6439 23.7874 22.766 23.7874 21.7338C23.7874 20.866 23.3351 20.1099 22.6566 19.6714C22.6377 19.6576 22.6442 19.6286 22.6696 19.6213C23.7961 19.2843 24.5885 18.1744 24.4029 16.9C24.2246 15.6756 24.935 14.1489 23.6975 14.1489L20.7631 14.0148C20.6587 14.0083 20.5536 14.0083 20.4506 14.0243C18.8703 14.2606 17.7735 15.6792 17.5742 16.8311Z"
                        fill="#E0C29C"
                      />
                      <path
                        opacity="0.182"
                        d="M17.9902 16.831C17.8982 17.3653 17.9786 17.8727 18.1852 18.3091C18.46 18.8905 18.4266 19.5813 17.9837 20.0474C17.5691 20.4831 17.3124 21.0703 17.3095 21.7206C17.3095 21.7437 17.3095 21.7662 17.3103 21.7894C17.3182 22.1352 17.3973 22.4643 17.5335 22.7623C17.7873 23.3183 17.7082 23.9772 17.3045 24.4361C17.2356 24.5144 17.1718 24.5977 17.1131 24.6876C16.8579 25.0776 16.7122 25.5532 16.7173 26.0527C16.7195 26.2629 16.7485 26.4666 16.8021 26.6587C16.8412 26.7986 16.8949 26.9371 16.9594 27.0683C17.0674 27.2879 17.1327 27.5112 17.1414 27.7091C17.1479 27.8541 17.1298 28.0288 17.0181 28.189C16.9601 28.2716 16.9036 28.3572 16.8391 28.4376C16.5904 28.7472 16.4636 29.1408 16.5107 29.5924C16.5977 30.4225 17.4487 31.0734 18.2831 31.0952C18.2875 31.0952 18.3244 31.0952 18.3288 31.0952H20.7398C21.92 31.0952 22.7348 30.4348 22.8008 29.5359C22.8392 29.0176 22.642 28.5442 22.3064 28.2115C23.1002 27.8019 23.6482 26.9842 23.6482 26.0295C23.6482 25.219 23.2531 24.5035 22.6493 24.0548C22.637 24.041 22.6435 24.0171 22.666 24.0077C23.5663 23.6409 24.2035 22.7637 24.2035 21.7314C24.2035 20.8615 23.7483 20.1033 23.0676 19.6654C23.0531 19.6524 23.061 19.6255 23.0864 19.6183C24.2129 19.2812 25.0053 18.1714 24.819 16.8977C24.6406 15.6733 25.3489 14.1517 24.1114 14.1517L21.061 14.0995C20.9595 14.0944 20.8573 14.0952 20.758 14.1104C19.1885 14.3445 18.1889 15.6805 17.9902 16.831Z"
                        fill="#DEBC96"
                      />
                      <path
                        opacity="0.273"
                        d="M18.4057 16.831C18.3136 17.3652 18.3941 17.8727 18.6007 18.3091C18.8754 18.8905 18.8421 19.5813 18.3991 20.0474C17.9852 20.4816 17.73 21.0674 17.725 21.7147C17.725 21.7379 17.725 21.7604 17.7257 21.7829C17.7329 22.1272 17.8098 22.4563 17.9439 22.7543C18.1954 23.3146 18.1128 23.9736 17.7032 24.4317C17.6336 24.51 17.5684 24.5941 17.5089 24.6847C17.2523 25.0776 17.1073 25.5611 17.1139 26.0628C17.1168 26.2737 17.1472 26.4789 17.2037 26.6724C17.2451 26.8131 17.3016 26.953 17.369 27.0856C17.4821 27.3075 17.5633 27.5286 17.5872 27.7141C17.6046 27.8475 17.6024 28.0273 17.4691 28.1882C17.4045 28.2665 17.3437 28.3492 17.2762 28.4282C17.0153 28.7305 16.8812 29.1183 16.9246 29.5779C17.0051 30.413 17.8525 31.0712 18.6905 31.0952C18.6971 31.0952 18.7326 31.0959 18.7398 31.0959H21.1553C22.3354 31.0959 23.1502 30.4355 23.2162 29.5366C23.2546 29.0183 23.0574 28.5449 22.7218 28.2122C23.5156 27.8026 24.0636 26.9849 24.0636 26.0302C24.0636 25.2175 23.6671 24.5013 23.0603 24.0526C23.0516 24.0395 23.0596 24.0171 23.0821 24.0084C23.9817 23.6416 24.6189 22.7644 24.6189 21.7329C24.6189 20.8608 24.1615 20.1011 23.4779 19.6639C23.467 19.6516 23.4772 19.6277 23.5018 19.6197C24.6276 19.2826 25.4199 18.1728 25.2344 16.8991C25.056 15.6747 25.7614 14.1575 24.524 14.1575L21.3582 14.1872C21.2597 14.1836 21.1611 14.185 21.0646 14.1995C19.5068 14.4286 18.6043 15.682 18.4057 16.831Z"
                        fill="#DBB791"
                      />
                      <path
                        opacity="0.364"
                        d="M18.8217 16.8313C18.7297 17.3655 18.8101 17.873 19.0167 18.3094C19.2915 18.8907 19.2581 19.5816 18.8152 20.0477C18.4027 20.4812 18.1476 21.064 18.141 21.7092C18.141 21.7317 18.141 21.7549 18.141 21.7774C18.1476 22.1203 18.223 22.4486 18.3542 22.7473C18.6028 23.312 18.5166 23.9717 18.1019 24.4284C18.0309 24.5067 17.9649 24.5915 17.9047 24.6836C17.6466 25.0794 17.5017 25.5709 17.5111 26.0747C17.5154 26.2871 17.5473 26.4937 17.6061 26.6872C17.6488 26.8286 17.709 26.9707 17.7793 27.1048C17.8975 27.3288 17.9939 27.5477 18.033 27.721C18.0606 27.8427 18.0751 28.0269 17.9199 28.1893C17.8489 28.2632 17.7837 28.3437 17.7126 28.4205C17.4393 28.7163 17.298 29.0969 17.3386 29.5659C17.4125 30.406 18.2556 31.0708 19.0987 31.0969C19.1074 31.0969 19.1422 31.0976 19.1509 31.0976H21.5699C22.7501 31.0976 23.5649 30.4372 23.6308 29.5383C23.6693 29.02 23.4721 28.5466 23.1364 28.2139C23.9302 27.8043 24.4783 26.9866 24.4783 26.0319C24.4783 25.2178 24.0803 24.5002 23.4714 24.0514C23.4663 24.0391 23.4757 24.0188 23.4975 24.0094C24.3971 23.6426 25.0336 22.7654 25.0336 21.7339C25.0336 20.8596 24.574 20.0985 23.8875 19.6613C23.8809 19.6497 23.8918 19.628 23.9172 19.62C25.043 19.2829 25.8353 18.1731 25.6497 16.8994C25.4714 15.675 26.1738 14.1628 24.9364 14.1628L21.6547 14.2752C21.559 14.2723 21.4633 14.2752 21.3705 14.289C19.825 14.5123 19.0204 15.6837 18.8217 16.8313Z"
                        fill="#D8B28B"
                      />
                      <path
                        opacity="0.455"
                        d="M19.2371 16.8313C19.145 17.3656 19.2255 17.873 19.4321 18.3094C19.7068 18.8908 19.6735 19.5816 19.2306 20.0478C18.8195 20.4798 18.5644 21.0605 18.5571 21.7027C18.5571 21.7252 18.5571 21.7477 18.5571 21.7702C18.5622 22.1116 18.6361 22.44 18.7652 22.7386C19.0109 23.3084 18.9218 23.9674 18.5013 24.4234C18.4295 24.5009 18.3628 24.5865 18.3012 24.6807C18.0417 25.0794 17.8967 25.5796 17.909 26.0849C17.9141 26.2987 17.9475 26.5068 18.0091 26.701C18.054 26.8431 18.1164 26.9867 18.1903 27.1222C18.3136 27.3484 18.4252 27.5644 18.4796 27.7261C18.5165 27.8355 18.5499 28.0262 18.3715 28.1878C18.2947 28.2582 18.2244 28.3357 18.1504 28.4097C17.8648 28.6989 17.7162 29.073 17.7532 29.55C17.8206 30.3952 18.6601 31.0672 19.5075 31.0948C19.5191 31.0955 19.5517 31.0955 19.5626 31.0955H21.986C23.1661 31.0955 23.981 30.4351 24.0469 29.5362C24.0853 29.0179 23.8882 28.5445 23.5525 28.2118C24.3463 27.8022 24.8943 26.9845 24.8943 26.0298C24.8943 25.2135 24.4942 24.4944 23.8831 24.0464C23.8816 24.0348 23.8925 24.0159 23.9143 24.0072C24.8132 23.6397 25.4496 22.7633 25.4496 21.7325C25.4496 20.856 24.9879 20.0934 24.2985 19.657C24.2963 19.6461 24.3086 19.6266 24.3333 19.6193C25.4591 19.2822 26.2507 18.1724 26.0651 16.8987C25.8868 15.6743 26.587 14.1665 25.3496 14.1665L21.9519 14.3608C21.8591 14.3593 21.7663 14.363 21.6772 14.376C20.144 14.5964 19.4357 15.6845 19.2371 16.8313Z"
                        fill="#D6AD86"
                      />
                      <path
                        opacity="0.545"
                        d="M19.6532 16.8313C19.5611 17.3656 19.6416 17.873 19.8482 18.3094C20.123 18.8908 20.0896 19.5817 19.6467 20.0478C19.2364 20.4784 18.9819 21.0569 18.9732 21.697C18.9732 21.7194 18.9732 21.7419 18.9732 21.7644C18.9776 22.1044 19.0494 22.4328 19.1762 22.7314C19.4191 23.3056 19.327 23.9652 18.9007 24.4198C18.8275 24.4973 18.7601 24.5836 18.6978 24.6786C18.4368 25.0802 18.2925 25.5883 18.307 26.0958C18.3136 26.3111 18.3484 26.5198 18.4122 26.7149C18.4593 26.8569 18.5245 27.0026 18.6014 27.1397C18.7297 27.368 18.8551 27.5811 18.9268 27.7312C18.9732 27.829 19.0254 28.0262 18.8239 28.1872C18.7413 28.2538 18.6659 28.3285 18.589 28.4003C18.2904 28.6823 18.1352 29.0505 18.1693 29.5355C18.2302 30.3858 19.066 31.0651 19.9171 31.0948C19.9309 31.0955 19.962 31.0955 19.9758 31.0955H22.4028C23.583 31.0955 24.3978 30.4351 24.4638 29.5362C24.5022 29.0179 24.305 28.5445 23.9694 28.2118C24.7632 27.8022 25.3112 26.9845 25.3112 26.0298C25.3112 25.2121 24.9096 24.4915 24.2963 24.0435C24.2985 24.0327 24.3108 24.0153 24.3326 24.0066C25.2307 23.639 25.8672 22.7626 25.8672 21.7318C25.8672 20.8532 25.4033 20.0891 24.711 19.6527C24.7131 19.6426 24.7269 19.6252 24.7508 19.6179C25.8766 19.2808 26.6682 18.171 26.4827 16.8973C26.3043 15.6729 27.0017 14.1702 25.7643 14.1702L22.2513 14.4471C22.1614 14.4471 22.0723 14.4514 21.986 14.4638C20.4622 14.6805 19.8511 15.686 19.6532 16.8313Z"
                        fill="#D3A780"
                      />
                      <path
                        opacity="0.636"
                        d="M20.0694 16.8311C19.9773 17.3654 20.0578 17.8728 20.2644 18.3092C20.5391 18.8906 20.5058 19.5815 20.0628 20.0476C19.654 20.4767 19.3995 21.0531 19.3894 21.691C19.3894 21.7135 19.3887 21.7352 19.3894 21.7577C19.393 22.0955 19.4626 22.4239 19.5873 22.7233C19.8272 23.3017 19.7316 23.9621 19.3002 24.4159C19.2263 24.4935 19.1574 24.5805 19.0944 24.6769C18.8319 25.0814 18.6877 25.5976 18.7043 26.1064C18.7116 26.3232 18.7478 26.5334 18.8145 26.7284C18.8631 26.8712 18.932 27.0191 19.0117 27.1569C19.1451 27.3874 19.2843 27.5983 19.3727 27.7361C19.4278 27.8223 19.5003 28.026 19.2756 28.1862C19.1871 28.2493 19.106 28.3203 19.0269 28.3907C18.7159 28.6654 18.5536 29.0271 18.584 29.5208C18.6384 30.3762 19.4699 31.0627 20.326 31.0939C20.3419 31.0946 20.3717 31.0946 20.3876 31.0946H22.819C23.9991 31.0946 24.8139 30.4342 24.8799 29.5353C24.9183 29.017 24.7212 28.5436 24.3855 28.2109C25.1793 27.8013 25.7273 26.9836 25.7273 26.0289C25.7273 25.2097 25.3236 24.4877 24.7081 24.0397C24.7139 24.0296 24.7277 24.0143 24.7494 24.0056C25.6476 23.6381 26.2826 22.7617 26.2826 21.7316C26.2826 20.8508 25.8165 20.0853 25.1213 19.6496C25.1271 19.6402 25.1431 19.625 25.167 19.6177C26.2928 19.2799 27.0844 18.1708 26.8981 16.8971C26.7198 15.6727 27.4142 14.1743 26.1768 14.1743L22.5479 14.5332C22.4609 14.5346 22.3746 14.5397 22.292 14.552C20.7805 14.7637 20.2665 15.6872 20.0694 16.8311Z"
                        fill="#D1A27B"
                      />
                      <path
                        opacity="0.727"
                        d="M20.4847 16.831C20.3926 17.3652 20.4731 17.8727 20.6797 18.3091C20.9544 18.8905 20.9211 19.5813 20.4782 20.0474C20.0708 20.4751 19.8163 21.0493 19.8047 21.685C19.804 21.7068 19.804 21.7292 19.8047 21.7517C19.8076 22.0881 19.875 22.4165 19.9975 22.7159C20.2346 23.2987 20.136 23.9591 19.6989 24.4122C19.6242 24.4897 19.5539 24.5767 19.4901 24.6753C19.2262 25.0827 19.082 25.6068 19.1015 26.1179C19.1095 26.3354 19.1479 26.5478 19.2168 26.7435C19.2676 26.887 19.3393 27.0364 19.422 27.1763C19.5604 27.409 19.7119 27.6177 19.8185 27.7432C19.8823 27.8178 19.9751 28.0295 19.7264 28.1875C19.6322 28.2477 19.5459 28.3151 19.464 28.3833C19.14 28.65 18.9711 29.0067 18.9986 29.5091C19.0465 30.3695 19.8743 31.0633 20.7341 31.0966C20.7522 31.0973 20.7812 31.0981 20.7993 31.0981H23.2343C24.4145 31.0981 25.2293 30.4377 25.2952 29.5388C25.3337 29.0205 25.1365 28.5471 24.8008 28.2144C25.5946 27.8048 26.1427 26.9871 26.1427 26.0324C26.1427 25.211 25.7374 24.4876 25.1198 24.0403C25.1292 24.0309 25.1445 24.0171 25.1655 24.0084C26.0629 23.6409 26.6979 22.7644 26.6979 21.7343C26.6979 20.8514 26.2297 20.0837 25.5316 19.6495C25.5417 19.6408 25.5591 19.6277 25.5823 19.6212C26.7074 19.2834 27.499 18.1743 27.3134 16.9006C27.1351 15.6762 27.8274 14.1829 26.5899 14.1829L22.8457 14.6236C22.7617 14.6265 22.679 14.6323 22.5993 14.6439C21.0987 14.8476 20.6826 15.6885 20.4847 16.831Z"
                        fill="#CE9D75"
                      />
                      <path
                        opacity="0.818"
                        d="M20.9008 16.8312C20.8087 17.3655 20.8892 17.8729 21.0958 18.3093C21.3706 18.8907 21.3372 19.5816 20.8943 20.0477C20.4883 20.4739 20.2346 21.0459 20.2208 21.6795C20.2201 21.7012 20.2201 21.7237 20.2201 21.7455C20.2216 22.0804 20.2883 22.4088 20.4079 22.7081C20.6427 23.2953 20.5398 23.9564 20.0976 24.4081C20.0215 24.4856 19.9504 24.5734 19.8859 24.6727C19.6206 25.083 19.4771 25.6151 19.4988 26.1283C19.5082 26.3472 19.5474 26.5603 19.6192 26.7568C19.6721 26.9011 19.746 27.0518 19.833 27.1932C19.9765 27.4281 20.1396 27.6361 20.2658 27.7478C20.3375 27.8116 20.4514 28.0305 20.1788 28.1856C20.0788 28.2429 19.9874 28.3067 19.9026 28.3719C19.5655 28.6322 19.3908 28.9816 19.414 29.4926C19.456 30.3582 20.2788 31.0592 21.1437 31.094C21.164 31.0947 21.1915 31.0954 21.2118 31.0954H23.6511C24.8313 31.0954 25.6461 30.435 25.7121 29.5361C25.7505 29.0178 25.5533 28.5444 25.2177 28.2117C26.0115 27.8021 26.5595 26.9844 26.5595 26.0297C26.5595 25.2069 26.1528 24.482 25.5323 24.0347C25.5453 24.026 25.5627 24.0144 25.583 24.0057C26.4798 23.6375 27.1148 22.7618 27.1148 21.7324C27.1148 20.8473 26.6443 20.0781 25.9433 19.6439C25.9578 19.6359 25.9767 19.6258 25.9999 19.6185C27.1249 19.2807 27.9166 18.1716 27.731 16.8979C27.5526 15.6735 28.242 14.1846 27.0046 14.1846L23.1444 14.708C23.0632 14.7123 22.9835 14.7188 22.9074 14.7297C21.4169 14.932 21.098 15.6902 20.9008 16.8312Z"
                        fill="#CB9870"
                      />
                      <path
                        opacity="0.909"
                        d="M21.3169 16.831C21.2249 17.3653 21.3053 17.8727 21.5119 18.3091C21.7867 18.8905 21.7533 19.5814 21.3104 20.0475C20.9052 20.473 20.6522 21.0421 20.637 21.6735C20.6362 21.6952 20.6362 21.717 20.6362 21.7387C20.637 22.0722 20.7015 22.3999 20.8189 22.7C21.0509 23.2915 20.9451 23.9534 20.4971 24.4035C20.4202 24.4811 20.3485 24.5688 20.2832 24.6703C20.0164 25.0828 19.8729 25.6236 19.8968 26.139C19.907 26.3593 19.9483 26.5739 20.0222 26.7704C20.0766 26.9146 20.1542 27.0683 20.2441 27.2104C20.3927 27.4474 20.5681 27.6555 20.7124 27.7526C20.7921 27.8063 20.9277 28.0325 20.6304 28.1847C20.5246 28.2391 20.4282 28.2985 20.3405 28.3623C19.9911 28.6153 19.8091 28.9589 19.8294 29.4779C19.8649 30.3486 20.6841 31.0583 21.5533 31.0931C21.5757 31.0938 21.6018 31.0945 21.6243 31.0945H24.0673C25.2474 31.0945 26.0622 30.4341 26.1282 29.5352C26.1666 29.0169 25.9695 28.5435 25.6338 28.2108C26.4276 27.8012 26.9756 26.9835 26.9756 26.0288C26.9756 25.2038 26.5668 24.4782 25.9448 24.0309C25.9615 24.0222 25.9803 24.0128 26.0006 24.0041C26.8974 23.6358 27.5317 22.7601 27.5317 21.7308C27.5317 20.8435 27.0583 20.0729 26.3551 19.6394C26.3732 19.6328 26.3943 19.6241 26.4167 19.6169C27.5418 19.2791 28.3327 18.17 28.1471 16.897C27.9688 15.6726 28.656 14.1887 27.4186 14.1887L23.4424 14.794C23.3634 14.7998 23.288 14.8071 23.2148 14.8172C21.7359 15.0159 21.5134 15.6915 21.3169 16.831Z"
                        fill="#C9926A"
                      />
                      <path
                        d="M27.947 21.733C27.947 20.8435 27.4714 20.0715 26.7654 19.638C27.926 19.3248 28.7516 18.1968 28.5624 16.8985C28.3841 15.6741 29.0684 14.1946 27.831 14.1946L23.7396 14.8818C22.0657 15.0435 21.9389 15.6386 21.733 16.8311C21.6409 17.3654 21.7214 17.8728 21.928 18.3092C22.2027 18.8906 22.1694 19.5814 21.7265 20.0476C21.3089 20.4861 21.0523 21.0777 21.0523 21.7323C21.0523 22.0643 21.1146 22.3919 21.2299 22.6928C21.459 23.2887 21.3502 23.9505 20.8964 24.4C20.8182 24.4775 20.7457 24.566 20.6797 24.6689C20.2962 25.2626 20.1694 26.1274 20.4252 26.7857C20.5753 27.172 20.8979 27.6295 21.1589 27.7592C21.2473 27.8027 21.4032 28.0369 21.082 28.1855C20.5238 28.4428 20.2223 28.8053 20.244 29.465C20.2737 30.3653 21.1349 31.096 22.036 31.096H24.4833C25.6635 31.096 26.4783 30.4356 26.5443 29.5367C26.5827 29.0184 26.3855 28.545 26.0499 28.2123C26.8437 27.8027 27.3917 26.985 27.3917 26.0303C27.3917 25.2039 26.9814 24.4761 26.3565 24.0295C27.2844 23.6765 27.947 22.7848 27.947 21.733Z"
                        fill="#C68D65"
                      />
                    </g>
                    <g opacity="0.24">
                      <path
                        opacity="0.091"
                        d="M5.8595 26.15H7.54203C8.95997 26.15 10.2895 26.7922 11.2079 27.8724C11.7067 28.4588 12.3091 28.9329 12.9898 29.204C13.8836 29.56 16.5143 29.7028 19.0936 29.7535C19.3466 29.7586 19.5996 29.7622 19.8504 29.7659C21.4742 29.7869 22.9958 29.7753 23.9078 29.7644C24.6856 29.7557 25.1872 29.7267 25.2228 29.697C25.4395 29.6825 25.6461 29.6201 25.8426 29.5361C25.9034 29.3193 25.9056 29.0844 25.8875 28.8314C25.8774 28.6865 25.8911 28.2885 25.8984 27.723C24.956 27.7346 23.575 27.7433 22.1034 27.7259C21.3966 27.718 20.6688 27.7035 19.9577 27.681C18.2469 27.6259 16.639 27.5208 15.6763 27.3294C15.3885 27.2714 15.15 27.2047 14.9717 27.1293C14.5549 26.9532 14.1678 26.7024 13.8169 26.3964C13.5965 26.2036 13.387 25.9934 13.1899 25.7701C12.5099 24.9995 11.6168 24.4631 10.6345 24.2391C10.2953 24.1528 9.83205 24.1079 9.47394 24.1079H9.03174C9.01361 24.1079 7.86825 24.0695 7.8552 24.0035C7.45214 23.902 7.11651 22.0005 7.01937 19.4481C6.99979 18.9131 6.99037 18.3549 6.99255 17.783C6.9969 16.8478 7.03532 15.9779 7.09476 15.1979C6.9389 15.1965 6.7816 15.1892 6.62356 15.1726C6.62574 15.1624 6.35679 15.153 6.35969 15.1443H6.0719C6.05813 15.1443 5.29769 15.1668 5.28682 15.2074C5.08094 16.3223 4.94828 18.0295 4.94611 19.9476C4.94611 20.0897 4.94683 20.2303 4.94756 20.3702C4.97655 23.5954 5.37381 26.15 5.8595 26.15Z"
                        fill="#E0C29C"
                      />
                      <path
                        opacity="0.182"
                        d="M5.72757 26.2819H7.4101C8.82804 26.2819 10.1575 26.9242 11.076 28.0043C11.5747 28.5908 12.1772 29.0649 12.8578 29.336C13.7596 29.6948 16.4295 29.8369 19.0298 29.8869C19.2864 29.892 19.5423 29.8956 19.7953 29.8993C21.5003 29.9203 23.0741 29.9043 23.8947 29.8949C24.645 29.8869 25.143 29.8405 25.2126 29.7818C25.4265 29.7528 25.6287 29.6774 25.8194 29.581C25.8404 29.4078 25.7853 29.2178 25.7483 28.983C25.728 28.8539 25.7019 28.4951 25.6287 27.9877C24.6798 27.9993 23.2894 28.008 21.8098 27.9906C21.0973 27.9819 20.3644 27.9674 19.6503 27.9442C17.9112 27.8869 16.2845 27.7782 15.3472 27.5774C15.0645 27.5157 14.8274 27.4432 14.6448 27.3621C14.2294 27.1765 13.8437 26.9184 13.4958 26.6045C13.2769 26.4066 13.0652 26.1949 12.8629 25.9746C12.167 25.2134 11.258 24.6922 10.2641 24.4907C9.95891 24.4131 9.41884 24.3725 9.09698 24.3725H8.65333C8.61708 24.3725 7.56813 24.2957 7.54131 24.1644C7.15565 23.9615 6.83814 22.0665 6.75042 19.5627C6.73303 19.019 6.72578 18.4565 6.73013 17.8852C6.73883 16.937 6.78232 16.0599 6.84829 15.2842C6.71128 15.261 6.57427 15.2349 6.43508 15.2023C6.43943 15.182 6.20021 15.1639 6.20529 15.1465H5.91822C5.89067 15.1465 5.19258 15.1922 5.16938 15.2719C4.95625 16.3716 4.8178 18.0947 4.81345 20.0404C4.81345 20.1825 4.81345 20.3246 4.8149 20.4659C4.84099 23.707 5.2397 26.2819 5.72757 26.2819Z"
                        fill="#DEBC96"
                      />
                      <path
                        opacity="0.273"
                        d="M5.59563 26.4139H7.27817C8.6961 26.4139 10.0256 27.0561 10.9441 28.1363C11.4428 28.7227 12.0452 29.1968 12.7259 29.4679C13.6357 29.8304 16.3454 29.9717 18.966 30.0196C19.2255 30.0247 19.4843 30.0283 19.7409 30.0312C21.5264 30.0515 23.1531 30.0326 23.8824 30.0239C24.6044 30.0174 25.1002 29.9529 25.2039 29.8645C25.4149 29.821 25.6128 29.7332 25.7976 29.6238C25.7795 29.4948 25.72 29.3331 25.6106 29.1323C25.6106 29.0163 25.5142 28.7002 25.3605 28.2493C24.4058 28.2609 23.006 28.2696 21.5177 28.2515C20.8 28.2428 20.0621 28.2276 19.3437 28.2044C17.5763 28.1457 15.9308 28.034 15.0196 27.8224C14.7419 27.7578 14.5056 27.6796 14.3193 27.5918C13.9054 27.3976 13.5211 27.1315 13.1754 26.8097C12.9579 26.6067 12.744 26.3943 12.5374 26.1761C11.8248 25.4251 10.9013 24.9183 9.89584 24.7393C9.62472 24.6704 9.00854 24.6342 8.7222 24.6342H8.2771C8.22273 24.6342 7.27019 24.5182 7.2296 24.3217C6.86061 24.0173 6.56195 22.1296 6.48438 19.6743C6.46843 19.1219 6.46408 18.555 6.47061 17.9845C6.48366 17.0233 6.53223 16.1382 6.60399 15.3676C6.48656 15.3233 6.36839 15.2777 6.24951 15.2291C6.25603 15.1987 6.04653 15.1711 6.05306 15.145H5.76526C5.72394 15.145 5.08747 15.2132 5.05267 15.3335C4.83302 16.418 4.68804 18.1578 4.68151 20.1303C4.68079 20.2731 4.68151 20.4159 4.68224 20.558C4.70616 23.8179 5.10631 26.4139 5.59563 26.4139Z"
                        fill="#DBB791"
                      />
                      <path
                        opacity="0.364"
                        d="M5.46442 26.5458H7.14696C8.56489 26.5458 9.89439 27.1881 10.8129 28.2682C11.3116 28.8546 11.914 29.3287 12.5947 29.5999C13.5125 29.9652 16.2613 30.1059 18.9022 30.153C19.1654 30.1573 19.427 30.1617 19.6858 30.1646C21.5525 30.1849 23.2307 30.1624 23.8693 30.1551C24.5631 30.1493 25.056 30.0674 25.1945 29.95C25.4025 29.892 25.5968 29.7912 25.7751 29.6695C25.7179 29.5839 25.6186 29.4607 25.4721 29.2845C25.4721 29.183 25.3257 28.9076 25.0923 28.5147C24.1318 28.5263 22.7218 28.535 21.2256 28.5168C20.5028 28.5081 19.7591 28.4929 19.037 28.4683C17.2407 28.4074 15.577 28.2914 14.6919 28.071C14.4193 28.0029 14.1844 27.9188 13.9938 27.8245C13.5813 27.6208 13.1986 27.3468 12.8557 27.0177C12.6389 26.8097 12.4236 26.5958 12.212 26.3805C11.4834 25.6389 10.5446 25.1474 9.52758 24.9908C9.28981 24.9307 8.59751 24.8988 8.34669 24.8988H7.90159C7.82983 24.8988 6.97298 24.7444 6.91861 24.4819C6.56702 24.0753 6.28648 22.1956 6.21834 19.7881C6.20456 19.227 6.20239 18.6558 6.21181 18.0853C6.22921 17.111 6.28286 16.2186 6.36042 15.4517C6.26183 15.3857 6.16324 15.3212 6.06393 15.2567C6.07263 15.2168 5.89212 15.1791 5.90155 15.145H5.61158C5.55649 15.145 4.98163 15.2356 4.93523 15.3958C4.70906 16.4651 4.55755 18.2216 4.54958 20.2209C4.54885 20.3644 4.54885 20.508 4.5503 20.6508C4.57133 23.9296 4.97221 26.5458 5.46442 26.5458Z"
                        fill="#D8B28B"
                      />
                      <path
                        opacity="0.455"
                        d="M5.33249 26.678H7.01502C8.43296 26.678 9.76245 27.3202 10.6809 28.4004C11.1797 28.9868 11.7821 29.4609 12.4628 29.732C13.3885 30.1003 16.1772 30.2402 18.8384 30.2859C19.1045 30.2902 19.3698 30.2939 19.6315 30.2968C21.5786 30.3163 23.3097 30.291 23.857 30.2844C24.5232 30.2794 25.0125 30.18 25.1851 30.0329C25.3902 29.9604 25.5801 29.8473 25.7527 29.7132C25.6555 29.6712 25.5163 29.5856 25.3344 29.4348C25.3344 29.3478 25.1379 29.1137 24.824 28.7781C23.857 28.7897 22.4376 28.7984 20.9327 28.7795C20.2042 28.7701 19.456 28.7549 18.7297 28.7302C16.9051 28.6671 15.2225 28.5475 14.3628 28.3177C14.0953 28.246 13.8611 28.1568 13.6669 28.056C13.2558 27.8436 12.8745 27.5616 12.5345 27.2253C12.3192 27.0122 12.1025 26.7976 11.8857 26.5845C11.1405 25.8523 10.1865 25.376 9.15787 25.2419C8.95417 25.1904 8.18503 25.1629 7.97046 25.1629H7.52319C7.4333 25.1629 6.67286 24.9701 6.60472 24.6424C6.2698 24.1342 6.00376 22.2611 5.95012 19.9029C5.93707 19.3331 5.93852 18.7575 5.95012 18.1877C5.97186 17.1997 6.03058 16.3001 6.11467 15.5382C6.03493 15.4505 5.95592 15.3671 5.8769 15.2859C5.88777 15.2359 5.73699 15.1888 5.74859 15.146H5.45935C5.39048 15.146 4.87724 15.2591 4.81997 15.4599C4.58655 16.5132 4.42997 18.2863 4.41909 20.3132C4.41837 20.4575 4.41837 20.6017 4.41909 20.746C4.43649 24.0414 4.83882 26.678 5.33249 26.678Z"
                        fill="#D6AD86"
                      />
                      <path
                        opacity="0.545"
                        d="M5.20056 26.8089H6.88309C8.30103 26.8089 9.63053 27.4512 10.549 28.5313C11.0477 29.1178 11.6501 29.5919 12.3308 29.863C13.2638 30.2349 16.0932 30.3741 18.7746 30.4183C19.0443 30.4226 19.3125 30.4263 19.5764 30.4292C21.604 30.4487 23.388 30.4183 23.8447 30.4139C24.4826 30.4081 24.9451 30.2603 25.1764 30.1167C25.3634 30.0007 25.5649 29.9036 25.7309 29.7572C25.5946 29.7593 25.4156 29.7108 25.1967 29.5854C25.1967 29.5129 24.9502 29.3193 24.5566 29.0417C23.5837 29.0533 22.1542 29.062 20.6413 29.0431C19.9077 29.0337 19.1545 29.0178 18.4238 28.9924C16.5709 28.9271 14.8702 28.8032 14.0358 28.564C13.7734 28.4886 13.5407 28.3936 13.3428 28.287C12.9325 28.0659 12.5534 27.776 12.2156 27.4316C12.0017 27.2134 11.7828 26.9974 11.5617 26.7872C10.8005 26.0644 9.83133 25.6034 8.79107 25.491C8.62144 25.4483 7.77546 25.4258 7.59713 25.4258H7.14914C7.04112 25.4258 6.3771 25.1945 6.29518 24.8009C5.97767 24.1913 5.72975 22.3253 5.68553 20.015C5.67466 19.4365 5.67756 18.8566 5.69205 18.2868C5.71815 17.2857 5.78267 16.3788 5.87183 15.6213C5.81167 15.5126 5.7515 15.4096 5.69205 15.3125C5.70583 15.2523 5.58404 15.1965 5.59781 15.145H5.3064C5.22376 15.145 4.77213 15.2813 4.70327 15.522C4.46332 16.5601 4.30021 18.3499 4.28716 20.4043C4.28644 20.5493 4.28644 20.6943 4.28716 20.8392C4.30166 24.1521 4.70544 26.8089 5.20056 26.8089Z"
                        fill="#D3A780"
                      />
                      <path
                        opacity="0.636"
                        d="M5.0686 26.9409H6.75113C8.16907 26.9409 9.49857 27.5831 10.417 28.6633C10.9158 29.2497 11.5182 29.7238 12.1989 29.9949C13.1398 30.3697 16.0083 30.5082 18.7108 30.5517C18.9834 30.556 19.2545 30.5596 19.522 30.5618C21.6308 30.5814 23.467 30.5473 23.8323 30.5437C24.4427 30.5386 24.9031 30.377 25.1676 30.2001C25.354 30.0754 25.549 29.9594 25.7092 29.8007C25.5337 29.8463 25.3141 29.8362 25.0589 29.7354C25.0589 29.6774 24.7624 29.5252 24.2883 29.3048C23.3097 29.3164 21.87 29.3251 20.3491 29.3055C19.6097 29.2961 18.8514 29.2794 18.1178 29.2533C16.2367 29.1867 14.5172 29.0576 13.7082 28.8097C13.4508 28.7307 13.2188 28.6306 13.0173 28.5176C12.6085 28.2885 12.2308 27.9891 11.8959 27.6375C11.6835 27.4142 11.4624 27.1975 11.2362 26.9902C10.4591 26.2768 9.47465 25.831 8.42279 25.7411C8.28723 25.707 7.36514 25.6889 7.22161 25.6889H6.77216C6.64602 25.6889 6.07769 25.4185 5.98272 24.9597C5.68261 24.2485 5.45716 22.3898 5.41874 20.1274C5.41076 19.5395 5.41511 18.9559 5.43179 18.3868C5.46223 17.3727 5.53182 16.4586 5.62679 15.7054C5.58547 15.5749 5.54487 15.4531 5.50573 15.34C5.52168 15.2697 5.42889 15.2045 5.44556 15.1443H5.15342C5.057 15.1443 4.667 15.303 4.58653 15.5836C4.33934 16.6064 4.17043 18.4129 4.15593 20.4942C4.15521 20.6399 4.15448 20.7856 4.15521 20.932C4.16681 24.2637 4.57131 26.9409 5.0686 26.9409Z"
                        fill="#D1A27B"
                      />
                      <path
                        opacity="0.727"
                        d="M4.93667 27.0731H6.6192C8.03714 27.0731 9.36664 27.7153 10.2851 28.7955C10.7839 29.3819 11.3863 29.856 12.067 30.1271C13.0159 30.5048 15.9242 30.6425 18.6463 30.6846C18.9225 30.6889 19.1965 30.6918 19.4662 30.6947C21.6554 30.7136 23.5446 30.6766 23.8186 30.6744C24.4007 30.6701 24.8617 30.4961 25.1568 30.2852C25.3424 30.1525 25.5308 30.0177 25.6852 29.8459C25.4707 29.935 25.2111 29.9626 24.919 29.8872C24.919 29.8437 24.5725 29.732 24.0187 29.5689C23.0335 29.5805 21.5844 29.5892 20.0555 29.5689C19.311 29.5588 18.5477 29.5421 17.8097 29.516C15.8996 29.4472 14.162 29.3138 13.3791 29.0571C13.1268 28.9745 12.8963 28.8694 12.6904 28.7498C12.2823 28.512 11.9067 28.2046 11.574 27.8451C11.3631 27.6167 11.1405 27.3985 10.9093 27.1941C10.1155 26.4902 9.11582 26.0596 8.05309 25.9922C7.9516 25.9661 6.95267 25.9531 6.84538 25.9531H6.39593C6.25167 25.9531 5.77975 25.6442 5.67101 25.1201C5.38757 24.3075 5.18097 22.4553 5.15197 20.2422C5.14617 19.6456 5.1527 19.0569 5.17155 18.4893C5.20634 17.4621 5.28101 16.54 5.38177 15.7912C5.3593 15.6397 5.33828 15.4983 5.3187 15.3693C5.33683 15.2888 5.27376 15.2141 5.29261 15.146H5.00047C4.89028 15.146 4.56189 15.3272 4.46983 15.6484C4.21611 16.656 4.04068 18.4792 4.024 20.5872C4.02255 20.7329 4.02255 20.8801 4.02255 21.0272C4.03198 24.3756 4.43793 27.0731 4.93667 27.0731Z"
                        fill="#CE9D75"
                      />
                      <path
                        opacity="0.818"
                        d="M4.80546 27.2047H6.48799C7.90593 27.2047 9.23542 27.847 10.1539 28.9271C10.6526 29.5136 11.255 29.9877 11.9357 30.2588C12.8926 30.6401 15.8401 30.7764 18.5832 30.8177C18.8623 30.8221 19.14 30.825 19.4125 30.8271C21.683 30.846 23.6243 30.8054 23.807 30.8039C24.3608 30.7996 24.8233 30.6155 25.1488 30.3683C25.3351 30.2269 25.5163 30.0732 25.6649 29.8898C25.4105 30.0232 25.1118 30.0877 24.7827 30.0377C24.782 30.0087 24.3862 29.9384 23.7519 29.8326C22.7609 29.8441 21.3024 29.8528 19.7648 29.8326C19.0153 29.8224 18.2469 29.8057 17.5045 29.7782C15.5661 29.7071 13.8104 29.5694 13.0528 29.3041C12.8056 29.2178 12.5758 29.1076 12.3663 28.9815C11.9589 28.7358 11.5856 28.419 11.2558 28.0522C11.0455 27.8187 10.8215 27.5998 10.5859 27.3976C9.77621 26.7031 8.76133 26.2877 7.68628 26.2428C7.61813 26.2254 6.54308 26.2167 6.47204 26.2167H6.01969C5.85731 26.2167 5.4818 25.8694 5.35929 25.2793C5.09325 24.3652 4.90477 22.5203 4.8852 20.355C4.88157 19.7497 4.89027 19.1567 4.91129 18.5891C4.95044 17.5488 5.03018 16.6195 5.13747 15.875C5.13457 15.7017 5.13312 15.5415 5.13312 15.3958C5.15342 15.3052 5.12007 15.2219 5.14109 15.1443H4.8475C4.72354 15.1443 4.45677 15.348 4.35383 15.709C4.09286 16.7014 3.91163 18.5405 3.89279 20.6761C3.89134 20.8226 3.89061 20.9704 3.89134 21.1191C3.89714 24.487 4.30381 27.2047 4.80546 27.2047Z"
                        fill="#CB9870"
                      />
                      <path
                        opacity="0.909"
                        d="M4.67351 27.3367H6.35604C7.77398 27.3367 9.10348 27.9789 10.0219 29.0591C10.5207 29.6455 11.1231 30.1196 11.8038 30.3907C12.7687 30.775 15.756 30.9112 18.5194 30.9504C18.8021 30.9547 19.082 30.9576 19.3574 30.9598C21.7083 30.9779 23.7019 30.9344 23.7939 30.9337C24.3202 30.9301 24.7856 30.7373 25.1394 30.4516C25.3257 30.3009 25.4996 30.1298 25.6425 29.9333C25.3489 30.1102 25.0096 30.2131 24.645 30.1878C24.6442 30.1733 24.1984 30.1435 23.4844 30.0957C22.4876 30.1073 21.0189 30.116 19.4734 30.095C18.7181 30.0848 17.9446 30.0674 17.1986 30.0399C15.2319 29.9674 13.4581 29.8231 12.7252 29.5506C12.483 29.46 12.254 29.3461 12.0408 29.212C11.6342 28.959 11.263 28.6328 10.9353 28.2588C10.7266 28.0203 10.5004 27.7999 10.2597 27.6005C9.43332 26.9155 8.40321 26.5153 7.31728 26.4929C7.28321 26.4842 6.13204 26.4798 6.0958 26.4798H5.64273C5.46295 26.4798 5.18313 26.0942 5.04684 25.4381C4.79747 24.4218 4.62784 22.5848 4.61842 20.4681C4.61624 19.8541 4.62784 19.2567 4.65104 18.6899C4.69453 17.6358 4.77935 16.6992 4.89244 15.9598C4.90839 15.7648 4.92651 15.5858 4.94608 15.4241C4.96855 15.3241 4.9642 15.2306 4.98813 15.145H4.69453C4.5568 15.145 4.35165 15.3719 4.23711 15.7728C3.96962 16.75 3.78259 18.6058 3.76084 20.7682C3.75939 20.9154 3.75867 21.064 3.75867 21.2133C3.76229 24.5979 4.17042 27.3367 4.67351 27.3367Z"
                        fill="#C9926A"
                      />
                      <path
                        d="M24.5065 30.3385C24.5065 30.3385 21.9642 30.3965 19.1806 30.3581C16.3969 30.3197 13.3696 30.1841 12.3968 29.797C11.7161 29.5259 11.1137 29.0518 10.6149 28.4654C9.69648 27.386 8.36625 26.743 6.94904 26.743H5.26651C4.76124 26.743 4.35239 23.9839 4.35239 20.5812C4.35239 18.2259 4.54884 16.1816 4.83663 15.1443H4.54159C4.03632 15.1443 3.62747 17.9033 3.62747 21.3061C3.62747 24.7088 4.03705 27.4679 4.54159 27.4679H6.22412C7.64206 27.4679 8.97156 28.1101 9.89003 29.1903C10.3888 29.7767 10.9912 30.2508 11.6719 30.5219C12.6447 30.9091 15.6712 31.0446 18.4556 31.083C21.2401 31.1215 23.7816 31.0635 23.7816 31.0635C24.5261 31.0635 25.2082 30.6082 25.62 29.9775C25.2873 30.1979 24.9081 30.3385 24.5065 30.3385Z"
                        fill="#C68D65"
                      />
                    </g>
                    <path
                      d="M28.3327 28.4595C28.1413 27.059 26.8509 26.0789 25.4504 26.2696C24.8995 26.3449 24.4174 26.5943 24.0419 26.9495L21.8918 27.9137C21.8048 27.9086 21.7171 27.9086 21.6279 27.9216C20.8138 28.0413 20.252 28.9061 20.3731 29.8528C20.4854 30.7336 21.1495 31.3664 21.8968 31.3548L25.9564 31.3476C26.0187 31.344 26.0803 31.3498 26.1434 31.3411C27.5439 31.1504 28.524 29.8601 28.3327 28.4595Z"
                      fill="url(#paint1_radial_57_52)"
                    />
                    <path
                      d="M30.1609 24.7414C29.9622 23.1046 28.4733 21.9389 26.8364 22.1375C26.1717 22.218 25.5917 22.5188 25.1423 22.9487L21.8338 23.8143C20.8805 24.0136 20.2201 25.0241 20.3455 26.147C20.468 27.2424 21.2944 28.0369 22.2346 28.0238L27.2917 28.071C27.3801 28.0681 27.4664 28.0768 27.5555 28.0659C29.1938 27.8673 30.3602 26.379 30.1609 24.7414Z"
                      fill="url(#paint2_radial_57_52)"
                    />
                    <path
                      d="M31.0083 20.4302C30.793 18.7955 29.2925 17.6451 27.6578 17.8604C27.565 17.8727 22.6022 19.569 22.6022 19.569C21.6279 19.756 20.9581 20.7883 21.098 21.9257C21.2263 22.9667 21.9882 23.7351 22.8719 23.7887C22.8719 23.7887 28.3138 23.7974 28.4385 23.7808C30.0732 23.5655 31.2236 22.0656 31.0083 20.4302Z"
                      fill="url(#paint3_radial_57_52)"
                    />
                    <path
                      d="M31.149 16.4651C30.9757 15.0305 29.7013 14.0055 28.274 14.1149C28.274 14.1149 28.0833 14.136 28.0449 14.1425L23.1372 14.9537C22.1963 15.1711 21.5489 16.2259 21.6772 17.4017C21.8019 18.5464 22.6153 19.3829 23.5446 19.3909L28.5132 19.4634C28.6096 19.4619 28.7038 19.4692 28.8017 19.4576C30.2762 19.28 31.3273 17.9403 31.149 16.4651Z"
                      fill="url(#paint4_radial_57_52)"
                    />
                    <path
                      opacity="0.15"
                      d="M14.0395 22.1767C11.1652 21.5953 8.5301 22.6305 8.15459 24.4877C7.77908 26.3449 9.8045 28.3225 12.6795 28.9032C15.5545 29.4838 18.1889 28.4494 18.5644 26.5921C18.9399 24.7349 16.9145 22.758 14.0395 22.1767Z"
                      fill="url(#paint5_radial_57_52)"
                    />
                    <path
                      opacity="0.59"
                      d="M19.8511 13.2582C19.6771 14.5972 19.256 15.9549 18.605 17.2264C18.2048 18.0072 17.7155 18.7618 17.1559 19.4454C16.2164 20.593 15.0348 21.597 13.6922 22.253C13.2384 22.4748 12.7629 22.6495 12.2765 22.7822C11.8321 22.9025 11.2594 23.0917 10.7947 23.025C10.6635 23.0243 10.533 22.9939 10.4243 22.9178C10.3301 22.8511 10.259 22.7518 10.2438 22.6358C10.2424 22.6263 10.2416 22.6162 10.2409 22.6068C10.2286 22.3588 10.4656 22.1457 10.7904 22.1327C13.4762 21.9891 16.2265 19.9094 17.809 16.8198C18.4578 15.5526 18.8565 14.1977 18.9921 12.8755L19.8511 13.2582Z"
                      fill="url(#paint6_linear_57_52)"
                    />
                    <path
                      opacity="0.15"
                      d="M14.7666 19.8344C12.5664 21.7039 9.88859 22.1672 8.78454 20.8688C7.68122 19.5705 8.56997 17.0021 10.7701 15.1326C12.9702 13.263 15.6481 12.7998 16.7521 14.0981C17.8562 15.3964 16.9667 17.9648 14.7666 19.8344Z"
                      fill="url(#paint7_radial_57_52)"
                    />
                    <path
                      opacity="0.02"
                      d="M26.4754 18.7252C28.8475 18.7252 30.7705 17.8878 30.7705 16.8549C30.7705 15.822 28.8475 14.9846 26.4754 14.9846C24.1032 14.9846 22.1802 15.822 22.1802 16.8549C22.1802 17.8878 24.1032 18.7252 26.4754 18.7252Z"
                      fill="url(#paint8_radial_57_52)"
                    />
                    <path
                      opacity="0.02"
                      d="M25.7824 23.491C28.1545 23.491 30.0775 22.7571 30.0775 21.8519C30.0775 20.9467 28.1545 20.2129 25.7824 20.2129C23.4102 20.2129 21.4872 20.9467 21.4872 21.8519C21.4872 22.7571 23.4102 23.491 25.7824 23.491Z"
                      fill="url(#paint9_radial_57_52)"
                    />
                    <path
                      opacity="0.02"
                      d="M25.0191 27.7241C27.3912 27.7241 29.3142 26.9903 29.3142 26.0851C29.3142 25.1799 27.3912 24.446 25.0191 24.446C22.6469 24.446 20.7239 25.1799 20.7239 26.0851C20.7239 26.9903 22.6469 27.7241 25.0191 27.7241Z"
                      fill="url(#paint10_radial_57_52)"
                    />
                    <path
                      opacity="0.02"
                      d="M24.0556 30.8204C25.7551 30.8204 27.1329 30.2946 27.1329 29.646C27.1329 28.9975 25.7551 28.4717 24.0556 28.4717C22.3561 28.4717 20.9783 28.9975 20.9783 29.646C20.9783 30.2946 22.3561 30.8204 24.0556 30.8204Z"
                      fill="url(#paint11_radial_57_52)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(12.549 15.664) scale(15.9931 14.4322)"
                      >
                        <stop stop-color="#E3C7A1" />
                        <stop offset="0.629" stop-color="#DAAE88" />
                        <stop offset="0.75" stop-color="#D7A983" />
                        <stop offset="0.899" stop-color="#CE9A73" />
                        <stop offset="0.999" stop-color="#C68D65" />
                      </radialGradient>
                      <radialGradient
                        id="paint1_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(23.736 30.03) scale(4.79605 2.43975)"
                      >
                        <stop stop-color="#E3C7A1" />
                        <stop offset="0.629" stop-color="#DAAE88" />
                        <stop offset="0.75" stop-color="#D7A983" />
                        <stop offset="0.899" stop-color="#CE9A73" />
                        <stop offset="0.999" stop-color="#C68D65" />
                      </radialGradient>
                      <radialGradient
                        id="paint2_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(24.8987 26.412) scale(5.54199 2.9295)"
                      >
                        <stop stop-color="#E3C7A1" />
                        <stop offset="0.629" stop-color="#DAAE88" />
                        <stop offset="0.75" stop-color="#D7A983" />
                        <stop offset="0.899" stop-color="#CE9A73" />
                        <stop offset="0.999" stop-color="#C68D65" />
                      </radialGradient>
                      <radialGradient
                        id="paint3_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(25.5903 22.5236) scale(6.00304 3.38751)"
                      >
                        <stop stop-color="#E3C7A1" />
                        <stop offset="0.629" stop-color="#DAAE88" />
                        <stop offset="0.75" stop-color="#D7A983" />
                        <stop offset="0.899" stop-color="#CE9A73" />
                        <stop offset="0.999" stop-color="#C68D65" />
                      </radialGradient>
                      <radialGradient
                        id="paint4_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(26.2132 16.9093) rotate(-4.7094) scale(5.29394 3.45652)"
                      >
                        <stop stop-color="#E3C7A1" />
                        <stop offset="0.629" stop-color="#DAAE88" />
                        <stop offset="0.75" stop-color="#D7A983" />
                        <stop offset="0.899" stop-color="#CE9A73" />
                        <stop offset="0.999" stop-color="#C68D65" />
                      </radialGradient>
                      <radialGradient
                        id="paint5_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(13.3562 25.5275) rotate(-78.5677) scale(3.0823 4.67034)"
                      >
                        <stop offset="0.425" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </radialGradient>
                      <linearGradient
                        id="paint6_linear_57_52"
                        x1="20.5064"
                        y1="13.5437"
                        x2="13.2317"
                        y2="21.4439"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#E3C7A1" stop-opacity="0" />
                        <stop offset="0.605" stop-color="#DAAE88" />
                        <stop
                          offset="0.999"
                          stop-color="#C68D65"
                          stop-opacity="0.64"
                        />
                      </linearGradient>
                      <radialGradient
                        id="paint7_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(12.7716 17.4777) rotate(49.6414) scale(2.77135 4.59804)"
                      >
                        <stop offset="0.425" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </radialGradient>
                      <radialGradient
                        id="paint8_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(26.4686 16.8556) rotate(-90) scale(1.67985 3.77753)"
                      >
                        <stop offset="0.425" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </radialGradient>
                      <radialGradient
                        id="paint9_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(25.7754 21.8608) rotate(-90) scale(1.47215 3.77753)"
                      >
                        <stop offset="0.425" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </radialGradient>
                      <radialGradient
                        id="paint10_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(25.0121 26.0939) rotate(-90) scale(1.47215 3.77753)"
                      >
                        <stop offset="0.425" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </radialGradient>
                      <radialGradient
                        id="paint11_radial_57_52"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(24.0492 29.6549) rotate(-90) scale(1.05486 2.70676)"
                      >
                        <stop offset="0.425" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </radialGradient>
                    </defs>
                  </svg>
                  <strong class="ms-2">Salderingsregeling verlengd!</strong>
                </p>
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          v-if="!showQuestions"
          class="col-12 col-lg-4 col-xl-5 col-xxl-6 d-lg-flex d-none"
        >
          <img src="../../assets/heroImg.png" alt="" class="hero-image" />
        </b-col>
      </b-row>
    </b-container>
    <div class="position-relative d-lg-none d-flex">
      <img src="../../assets/heroImg.png" alt="" class="hero-image" />
      <img src="../../assets/bg_blue.png" alt="" class="hero-image__sm-bg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  props: {
    showQuestions: Boolean,
  },
  data() {
    return {
      points: [
        // "Geen investering, lening of aanbetaling nodig",
        // "Inclusief verzekering en garantie",
        // "Compleet pakket met A-kwaliteit zonnepanelen",
        "Geen investering, lening of aanbetaling nodig",
        "Snel geplaatst en inclusief verzekering en garantie",
        "Compleet pakket met A-kwaliteit panelen vanaf €1,- p/d",
      ],
      mobilePoints: [
        "Geen investering, lening of aanbetaling nodig",
        "Inclusief verzekering en garantie",
        "Compleet pakket met A-kwaliteit zonnepanelen",
      ],
    };
  },
  methods: {
    showSecondForm() {
      this.$emit("show-second-form");
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  background-color: transparent;
  // border-radius: 1rem !important;
  color: white;

  &__top {
    // padding: 1.5rem !important;
    // padding-left: 0;

    @media (max-width: 525px) {
      padding: 0 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  .offer {
    width: 125px;
    height: 125px;
  }
  .offer_img {
    position: absolute;
    top: 40%;
    width: 280px;
    left: 100%;
    transform: rotate(5deg);
    @media (max-width: 1199.98px) {
      left: 85%;
      top: 35%;
    }
    @media (max-width: 991.98px) {
      transform: rotate(355deg);
      position: static;
      left: 0px;
      top: 0px;
      width: 120px;
      height: 120px;
    }
  }

  &__heading {
    font-size: 55px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0.18460631370544434px;
    text-align: left;
    margin-bottom: 10px;
    span {
      color: #62cfb3;
    }
  }

  &__sub_heading {
    font-size: 22px;
    @media (max-width: 767.98px) {
      font-size: 17px;
      text-align: center;
    }
    .gratis {
      color: #62cfb3;
    }
  }
  .sm_badge {
    @media (max-width: 991.98px) {
      background-color: #ffffff;
      border-radius: 5px;
      color: black;
    }
  }
  .points__div {
    p {
      font-size: 16px;
      @media (max-width: 767.98px) {
        font-weight: 500;
        display: flex;
        font-size: 14px;
      }
    }
  }

  .profit__div {
    p {
      font-weight: 600;
      font-size: 16px;
      @media (max-width: 767.98px) {
        font-size: 13px;
        line-height: 16px;
        // font-weight: 400;
      }
    }
  }

  @media (max-width: 992px) {
    &__heading {
      font-size: 55px;
    }
  }

  @media (max-width: 767.98px) {
    &__heading {
      font-size: 40px;
      line-height: 44px;
    }
  }
  @media (max-width: 450px) {
    &__heading {
      font-size: 31px;
      line-height: 35px;
    }
  }

  @media (max-width: 375px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 350px) {
    &__heading {
      line-height: 30px;
      font-size: 27px;
    }
    p {
      font-size: 11px;
    }
  }
}
.hero-image__sm-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  z-index: -1;

  @media (max-width: 991.98px) {
    width: 100%;
    height: 460px;
  }
  @media (max-width: 767.98px) {
    height: 385px;
  }
  @media (max-width: 599.98px) {
    height: 300px;
  }
  @media (max-width: 499.98px) {
    height: 250px;
  }
}
.hero-image {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 150px;
  width: 100%;
  height: 100%;
  @media (max-width: 1199.98px) {
    object-fit: cover;
    margin-left: 50px;
    width: auto;
  }
  @media (max-width: 991.98px) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    height: 380px;
    object-position: top;
  }
  @media (max-width: 767.98px) {
    height: 320px;
  }
  @media (max-width: 599.98px) {
    height: 240px;
  }
  @media (max-width: 499.98px) {
    height: 200px;
  }
}
</style>
