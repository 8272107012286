<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <h2 class="title mb-3">Hallostroom verwerkt nu jouw gegevens…</h2>
      <b-row
        v-for="(item, index) of points"
        :key="index"
        v-if="indexes.includes(index)"
        class="mb-2 d-flex justify-content-start align-items-start mx-1 mx-sm-0"
      >
        <b-col class="col-1 p-0">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#079ED0" />
            <path
              d="M7.14941 12.1688L10.6578 15.2392C11.1119 15.6367 11.812 15.5443 12.1476 15.0427L16.8508 8.01099"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </b-col>
        <b-col class="col-11 p-0">
          <p class="mb-0 point">
            <strong>{{ item }}</strong>
          </p>
        </b-col>
      </b-row>
      <!-- <div class="text-start mb-1 d-flex justify-content-start align-items-start" v-for="(item, index) of points"
       >
       
      </div> -->
      <p class="input__form-label mt-3" v-if="indexes.includes(3)">
        Wij sturen je nu door om een offerte op maat te ontvangen.
      </p>
      <div class="text-center pt-3">
        <!-- <b-spinner variant="success"></b-spinner> -->

        <b-progress :max="100" :value="value">
          <b-progress-bar :value="value"></b-progress-bar>
        </b-progress>
      </div>
    </b-form>
  </b-container>
</template>
<script>
export default {
  name: "Analyze",
  props: {
    step: {
      type: Number,
    },
  },
  data() {
    return {
      value: 2,
      points: [
        "Jouw dak is geschikt voor zonnepanelen",
        "Jij komt in aanmerking voor zonnepanelen zonder investering",
        "Jij hebt recht op 3 maanden GRATIS zonnepanelen",
      ],
      indexes: [],
    };
  },
  mounted() {
    let seconds = 0;

    const intervalId = setInterval(() => {
      this.indexes.push(seconds);
      seconds++;
      this.value = this.value + 20;

      if (seconds === 6) {
        clearInterval(intervalId);
        this.$emit("nextStep", this.step + 1);
      }
    }, 3000);

    // const interval = setInterval(() => {
    //   if (this.value < 20) {
    //     this.value += 1;
    //   } else if (!this.seconds == 1) {
    //     this.seconds = 1;
    //     this.value = 20;
    //   } else if (this.value < 40) {
    //     this.value += 1;
    //   } else if (!this.seconds == 2) {
    //     this.seconds = 2;
    //     this.value = 40;
    //   } else if (this.value < 60) {
    //     this.value += 1;
    //   } else if (!this.seconds == 3) {
    //     this.seconds = 3;
    //     this.value = 60;
    //   } else if (this.value < 80) {
    //     this.value += 1;
    //   } else if (!this.seconds == 4) {
    //     this.seconds = 4;
    //     this.value = 80;
    //   } else if (this.value < 100) {
    //     this.value += 1;
    //   } else if (!this.seconds == 5) {
    //     this.seconds = 5;
    //     this.value = 100;
    //     clearInterval(interval);
    //     setTimeout(() => {
    //       // perform your function here
    //       // this.$emit("nextStep", this.step + 1);
    //     }, 3000);
    //   }
    // }, 60);
  },
};
</script>
<style lang="scss" scoped>
.input__form {
  // min-height: 50vh !important;
}

.title {
  font-size: 30px;
}

@media (max-width: 450px) {
  .point {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}
.progress {
  // height: 20px;
  height: 5px;
  border-radius: 14px;
  background-color: #079ed0 !important;
  // border: 1px solid #079ed0;
}

.progress-bar {
  height: 5px;
  border-radius: 14px;
  border: 1px solid #62cfb3;
  background-color: #62cfb3;
}
</style>
