<template>
  <div class="home">
    <HeroSection :showQuestions="showQuestions" @update="toggleQuestion" />
    <div v-if="!showQuestions">
      <GuideSection :showQuestions="showQuestions" @update="toggleQuestion" />
      <OverHollostroom
        :showQuestions="showQuestions"
        @update="toggleQuestion"
      />
      <ZonnepanelenHuren
        :showQuestions="showQuestions"
        @update="toggleQuestion"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/components/HeroSection.vue";
import GuideSection from "../components/GuideSection.vue";
import FinancialAdvice from "../components/FinancialAdvice.vue";
import ZonnepanelenHuren from "../components/ZonnepanelenHuren.vue";
import OverHollostroom from "../components/OverHollostroom.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  data() {
    return {
      showQuestions: false,
    };
  },
  components: {
    HeroSection,
    GuideSection,
    FinancialAdvice,
    ZonnepanelenHuren,
    OverHollostroom,
    Footer,
  },
  methods: {
    toggleQuestion() {
      this.showQuestions = true;
    },
  },
};
</script>
