<template>
  <b-container fluid class="footer mx-md-5 mx-0 px-md-3 px-2">
    <div class="text-center py-4 border-top">
      <p class="mb-0">Bekijk de <span class="cursor-pointer underline" v-b-modal.PrivacyPolicy>
          Privacy
        </span> &
        <span class="cursor-pointer underline" v-b-modal.termsConditions>
          voorwaarden
        </span> van deze actie
      </p>
    </div>

    <div>
      <b-modal id="PrivacyPolicy" centered title="Privacy Policy" ok-only size="lg" ok-variant="success" scrollable>

        <div class="px-3">
          <p>
            Via onze webwinkel worden privacygevoelige gegevens oftewel persoonsgegevens verwerkt. HalloStroom Markt &
            Klant B.V. acht een zorgvuldige omgang met persoonsgegevens van groot belang. Persoonlijke gegevens worden
            door ons dan ook zorgvuldig verwerkt en beveiligd.
          </p>
          <p>
            Bij onze verwerking houden wij ons aan de eisen die de privacywetgeving stelt. Dat betekent onder andere
            dat: </p>

          <ul>
            <li>wij <strong>duidelijk vermelden</strong> met <strong>welke doeleinden</strong> wij persoonsgegevens
              verwerken. Dat doen wij via deze privacyverklaring;</li>
            <li>wij onze <strong>verzameling van persoonsgegevens beperken</strong> tot alleen de persoonsgegevens die
              nodig zijn voor legitieme doeleinden;</li>
            <li>wij u eerst <strong>vragen om uitdrukkelijke toestemming</strong> om uw persoonsgegevens te verwerken in
              gevallen waarin uw toestemming is vereist;</li>
            <li>wij <strong>passende beveiligingsmaatregelen</strong> nemen om uw persoonsgegevens te beschermen en dat
              ook eisen van partijen die in onze opdracht persoonsgegevens verwerken;
            </li>
            <li>wij uw recht respecteren om uw <strong>persoonsgegevens op uw aanvraag ter inzage te bieden, te
                corrigeren of te verwijderen.</strong>
            </li>
            <li>HalloStroom Markt & Klant B.V. is de verantwoordelijke voor de gegevensverwerking. In deze
              privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen en gebruiken en met welk doel. Wij
              raden u aan deze zorgvuldig te lezen.
            </li>
          </ul>
          <i class="mb-3">Deze privacyverklaring is voor het laatst aangepast op 08-05-2018 00:00:00.</i>
          <div class="content_box">
            <h4>Gebruik van persoonsgegevens</h4>
            <p>
              Bij het gebruiken van onze webwinkel verkrijgen wij bepaalde gegevens van u. Dat kunnen persoonsgegevens
              zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door u worden opgegeven,
              in het kader van de door u gevraagde dienst, of waarvan bij opgave duidelijk is dat ze aan ons worden
              verstrekt om te verwerken.
            </p>
            <p>
              Wij gebruiken de volgende gegevens voor de in deze privacyverklaring genoemde doelen:</p>

            <ul>
              <li>
                NAW gegevens</li>
              <li>Telefoonnummer</li>
              <li>Emailadres</li>
              <li>Betalingsgegevens</li>
              <li>Geslacht</li>
              <li>Geboortedatum</li>
              <li>IP-adres</li>
              <li>Verbruiksgegevens energie</li>
            </ul>
          </div>
          <div class="content_box">
            <h4>Registreren</h4>
            <p>
              Bij bepaalde onderdelen van onze webwinkel moet u zich eerst registreren. Na registratie bewaren wij via
              de door u gekozen gebruikersnaam de door u opgegeven persoonsgegevens. Wij bewaren deze gegevens zodat u
              deze niet elke keer opnieuw hoeft in te vullen en zodat wij u kunnen contacteren in het kader van
              uitvoering van de overeenkomst.<br />
              Wij zullen de aan uw gebruikersnaam gekoppelde gegevens niet aan derden verstrekken, tenzij dat
              noodzakelijk is in het kader van de uitvoering van de overeenkomst die u met ons sluit of indien dit
              wettelijk verplicht is. In geval van een vermoeden van fraude of misbruik van onze webwinkel kunnen wij
              persoonsgegevens aan de bevoegde autoriteiten overhandigen.
            </p>
          </div>
          <div class="content_box">
            <h4>Afhandelen bestelling</h4>
            <p>
              Wanneer u bij ons een bestelling plaatst, maken wij voor de afhandeling daarvan gebruik van uw
              persoonsgegevens. Indien dat nodig is voor een goede afhandeling kunnen wij uw persoonsgegevens ook aan
              derden verstrekken. Meer daarover leest u verderop in deze privacyverklaring.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Een abonnement afsluiten </h4>
            <p>Via <a href="https://hallostroom.nl/" target="_blank">hallostroom.nl</a> kunt u een betaald abonnement
              afsluiten. Voor dit doel vragen wij aan u de voor de betaling benodigde gegevens. Deze worden niet voor
              andere doeleinden gebruikt.
              <br />In kaart brengen van zonnestroomsysteem a.h.v. verbruiksgegevens
            </p>
            <h4>
              Reclame </h4>
            <p>Wij kunnen u, naast de informatie op onze website, ook op de hoogte brengen van onze nieuwe producten en
              diensten:</p>
            <ul>
              <li>per post
              </li>
              <li>
                per e-mail</li>
              <li>
                via social media</li>
            </ul>




          </div>
          <div class="content_box">
            <h4>
              Contactformulier en nieuwsbrief
            </h4>
            <p>
              Wij bieden via onze webwinkel de mogelijkheid om vragen te stellen middels een contactformulier, waarbij u
              gevraagd wordt diverse gegevens in te vullen om uw vraag te behandelen. U kiest zelf welke gegevens u
              daarbij verstrekt. De gegevens die u ons toestuurt, worden bewaard zolang als naar de aard van het
              formulier of de inhoud van uw e-mail nodig is voor de volledige beantwoording en afhandeling daarvan.
              <br />Wij bieden een nieuwsbrief waarmee wij geïnteresseerden willen informeren over onze producten en/of
              diensten. Iedere nieuwsbrief bevat een link waarmee u zich kunt afmelden. Uw e-mailadres wordt automatisch
              toegevoegd aan de lijst van abonnees.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Locatiegegevens
            </h4>
            <p>
              Als dat nodig is voor de dienst, kunnen wij locatiegegevens (GPS) van u verzamelen. Hiervoor wordt op het
              moment zelf uw toestemming gevraagd.
              <br />Tevens kunnen deze (locatie) gegevens opgeslagen en verwerkt worden door de aanbieder van
              bijvoorbeeld de navigatie/kaartensoftware, zoals Google Maps, maar de gegevens kunnen ook worden gebruikt
              door bijvoorbeeld Google of Apple zelf. Wij hebben hier geen invloed op. Wij raden u aan de relevante
              privacyverklaringen te lezen van de desbetreffende aanbieder.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Publicatie
            </h4>
            <p>
              Wij publiceren uw klantgegevens niet.</p>
          </div>
          <div class="content_box">
            <h4>
              Advertenties
            </h4>
            <p>
              Onze webwinkel vertoont advertenties.</p>
          </div>

          <div class="content_box">
            <h4>
              Verstrekking aan derden

            </h4>
            <p>
              Wij kunnen uw gegevens doorgeven aan onze partners. Deze partners zijn betrokken bij de uitvoering van de
              overeenkomst. Deze partners zijn (mede) gevestigd buiten de EU.
            </p>
            <p>
              In onze webwinkel zijn social media buttons opgenomen. Hiermee verzamelen de beheerders van deze diensten
              uw persoonsgegevens.</p>
          </div>
          <div class="content_box">
            <h4>
              Cookies
            </h4>
            <p>
              Op onze webwinkel wordt gebruik gemaakt van cookies. Ook via derden die door ons zijn ingeschakeld worden
              cookies geplaatst.
              <br />Wanneer u onze webwinkel voor het eerst bezoekt, wordt er een mededeling getoond waarin wij u
              uitleggen waarom wij cookies gebruiken. Uw verder gebruik van onze webwinkel vatten wij op als toestemming
              voor dit gebruik van cookies.
              <br />Het staat u vrij om cookies uit te schakelen middels uw browser. Houd er wel rekening mee dat het
              mogelijk is dat onze website dan niet meer optimaal werkt.
              <br />Met derden die cookies plaatsen hebben wij afspraken gemaakt over het gebruik van de cookies en
              applicaties. Toch hebben wij geen volledige controle op wat de aanbieders van deze applicaties zelf met de
              cookies doen wanneer zij deze uitlezen. Voor meer informatie over deze applicaties en hoe zij met cookies
              omgaan, zie graag de privacyverklaringen van deze partijen (let op: deze kunnen regelmatig wijzigen).
            </p>
          </div>
          <div class="content_box">
            <h4>
              Google Analytics
            </h4>
            <p>
              Wij gebruiken Google Analytics om bij te houden hoe bezoekers onze webwinkel gebruiken. Wij hebben een
              bewerkersovereenkomst met Google gesloten om afspraken te maken over de omgang met onze data. Verder
              hebben wij Google niet toegestaan de verkregen Analytics informatie te gebruiken voor andere Google
              diensten, tot slot laten wij de IP-adressen anonimiseren.
            </p>
          </div>

          <div class="content_box">
            <h4>
              Beveiliging
            </h4>
            <p>
              Wij nemen beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot persoonsgegevens te
              beperken.</p>
          </div>
          <div class="content_box">
            <h4>
              Bewaartermijnen
            </h4>
            <p>
              De hierboven beschreven persoonsgegevens worden bewaard voor zo lang als nodig om uw bestellingen af te
              handelen, inclusief garantie. Daarna bewaren wij gegevens nog maximaal een jaar voor de beschreven
              statistische doeleinden. De gegevens worden vervolgens gewist, tenzij er een wettelijke plicht is die
              langer bewaren vereist (zoals de fiscale bewaarplicht van zeven jaar voor betaalgegevens). </p>
          </div>
          <div class="content_box">
            <h4>
              Websites van derden
            </h4>
            <p>Deze privacyverklaring is niet van toepassing op websites van derden die door middel van links met onze
              webwinkel zijn verbonden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier
              met uw persoonsgegevens omgaan. Wij raden u aan de privacyverklaring van deze websites te lezen alvorens
              van deze websites gebruik te maken.</p>

          </div>
          <div class="content_box">
            <h4>
              Wijzigingen in deze privacyverklaring
            </h4>
            <p>Wij behouden ons het recht voor om wijzigingen aan te brengen in deze privacyverklaring. Het verdient
              aanbeveling om deze privacyverklaring geregeld te raadplegen, zodat u van deze wijzigingen op de hoogte
              bent.</p>
          </div>
          <div class="content_box">
            <h4>
              Inzage en wijzigen van uw gegevens
            </h4>
            <p>Voor vragen over ons privacybeleid of vragen omtrent inzage en wijzigingen in (of verwijdering van) uw
              persoonsgegevens kunt u te allen tijde contact met ons opnemen via onderstaande gegevens.
              <br />U kunt ons ook een verzoek toesturen om deze gegevens in te zien, te wijzigen of te verwijderen. Ook
              kunt u een verzoek indienen om een gegevensexport op te vragen voor gegevens die wij met uw toestemming
              gebruiken, of gemotiveerd aangeven dat u de verwerking van persoonsgegevens door ons wil laten beperken.
              <br />Om misbruik te voorkomen kunnen wij u daarbij vragen om u adequaat te identificeren. Wanneer het
              gaat om inzage in persoonsgegevens gekoppeld aan een cookie, dient u een kopie van het cookie in kwestie
              mee te sturen. U kunt deze terug vinden in de instellingen van uw browser. Indien de gegevens niet
              kloppen, kunt u ons verzoeken om de gegevens te wijzigen of te laten verwijderen.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Autoriteit Persoonsgegevens
            </h4>
            <p>Natuurlijk helpen wij u graag verder als u klachten heeft over de verwerking van uw persoonsgegevens. Op
              grond van de privacywetgeving heeft u ook het recht om een klacht in te dienen bij de Autoriteit
              Persoonsgegevens tegen deze verwerkingen van persoonsgegevens. U kunt hiervoor contact opnemen met de
              Autoriteit Persoonsgegevens.</p>
          </div>
          <div class="content_box">
            <h4>
              Contactgegevens
            </h4>
            <p>HalloStroom Markt & Klant B.V.<br />
              Bouwmeesterplein 1

              <br />
              2801BX Gouda<br />
              <a href="mailto:klantenservice@hallostroom.nl" >klantenservice@hallostroom.nl</a><br />
              <a href="tel:0307670078" >0307670078</a><br />
            </p>
          </div>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="termsConditions" centered title="Algemene Voorwaarden HalloStroom – Huur Versie 2023 " ok-only
        size="lg" ok-variant="success" scrollable>

        <div class="px-3">
          <p>
            Deze Algemene Voorwaarden zijn van toepassing wanneer u een Energieproduct huurt van HalloStroom en maken
            onderdeel uit van uw overeenkomst met HalloStroom.
          </p>

          <div class="content_box">
            <p>
            <ol class="ps-3">
              <li class="fw-bold">Definities en interpretatie</li>
              In deze algemene voorwaarden wordt verstaan onder:
              <ol class="ps-3">
                <li>
                  Aanvraag: het verzoek van de Klant aan HalloStroom voor een offerte;
                </li>
                <li>
                  Algemene Voorwaarden: deze algemene voorwaarden HalloStroom - huur, versie 2023;
                </li>
                <li>
                  HalloStroom: HalloStroom Markt & Klant B.V., statutair gevestigd te Gouda en daar kantoorhoudende aan
                  het (2801 BX) Bouwmeesterplein 1 (KvK: 71180907), dan wel één van haar groepsmaatschappijen als
                  bedoeld in artikel 2:24b van het Burgerlijk Wetboek; </li>
                <li>
                  Huurprijs: de periodiek verschuldigde vergoeding voor de huur van het Energieproduct, zoals
                  overeengekomen in de Overeenkomst; </li>
                <li>
                  Klant: Een natuurlijk persoon, al dan niet handelend in de uitoefening van beroep of bedrijf, met wie
                  HalloStroom schriftelijk een Overeenkomst aangaat; </li>
                <li>
                  Medewerker: De werknemer en/of andere hulppersoon die onder de verantwoordelijkheid valt van
                  HalloStroom en door haar wordt ingeschakeld voor onder andere het installeren van- en adviseren over
                  het Energieproduct; </li>
                <li>
                  Overeenkomst: De overeenkomst tussen HalloStroom en de Klant zoals bedoeld in artikel 7:201 van het
                  Burgerlijk Wetboek, waarin de afspraken over de huur van het Energieproduct en aanverwante onderdelen
                  en diensten zijn vastgelegd; </li>
                <li>
                  Partijen: HalloStroom en de Klant gezamenlijk; </li>
                <li>
                  Schriftelijk: Zal mede inhouden per e-mail of vergelijkbare elektronische communicatiemiddelen; </li>
                <li>
                  EigenBijdrage:Eenoptionelebijdragevooreendeelvandeinstallatiekosten,diedoorKlantwordt betaald bij
                  installatie van het Energieproduct; </li>
                <li>
                  Energieproduct: Alle producten, en daarmee samenhangende diensten, die door HalloStroom worden
                  aangeboden inclusief, doch niet beperkt tot, zonnestroomsystemen, laadpalen, warmtepompen,
                  thuisbatterijen en andere energie-opslagsystemen. </li>
                <li>
                  Woning: De woongelegenheid van de Klant alsmede de op het perceel gelegen bijgebouwen, in eigendom van
                  de Klant, waarop het Energieproduct wordt aangebracht; </li>
                <li>
                  Website: De website van HalloStroom waarop HalloStroom Energieproducten aan particulieren aanbiedt
                  voor de verhuur daarvan; </li>
              </ol>
              <li class="fw-bold">Toepasselijkheid Algemene Voorwaarden </li>
              <ol class="ps-3">
                <li>
                  De Algemene Voorwaarden zijn van toepassing op alle aanbiedingen en offertes van, en Overeenkomsten
                  gesloten met, HalloStroom, alsmede op alle overige rechtshandelingen die daarop betrekking hebben.
                  Deze Algemene Voorwaarden vormen een integraal onderdeel van de Overeenkomst. Elke verwijzing naar de
                  Overeenkomst houdt tevens een verwijzing in naar deze Algemene Voorwaarden, tenzij uitdrukkelijk
                  anders is bepaald. In geval van strijdigheid tussen de
                  bepalingen van deze Algemene Voorwaarden en de Overeenkomst (zonder de Algemene Voorwaarden),
                  prevaleert hetgeen bepaald in de Overeenkomst.
                </li>
                <li>
                  Niettegenstaande hetgeen is bepaald in artikel 2 lid 3, kan van deze Algemene Voorwaarden slechts
                  worden afgeweken door middel van een uitdrukkelijke, schriftelijke verklaring van beide Partijen.
                </li>
                <li>
                  HalloStroom is gerechtigd de Algemene Voorwaarden eenzijdig te wijzigen. Bekendmaking van een
                  wijziging vindt plaats door middel van een persoonlijke kennisgeving per e-mail of bij brief. De Klant
                  is in een voorkomend geval gerechtigd binnen 21 dagen na dagtekening van de e- mail of brief kenbaar
                  te maken niet akkoord te willen gaan met de gewijzigde Algemene Voorwaarden. Alsdan blijft Klant
                  gebonden aan de Algemene Voorwaarden zoals die voorheen, dat wil zeggen voordat deze eenzijdige door
                  HalloStroom gewijzigd warden, van kracht waren. Indien Klant niet binnen 21 dagen na dagtekening van
                  de e-mail of brief kenbaar maakt niet akkoord te willen gaan met de gewijzigde Algemene Voorwaarden,
                  wordt Klant geacht stilzwijgend akkoord te zijn gegaan met de gewijzigde Algemene Voorwaarden.
                </li>
                <li>
                  Indien een bepaling van deze Algemene Voorwaarden nietig is of vernietigd wordt, zullen de overige
                  bepalingen van kracht blijven en zal de nietige of vernietigde bepaling automatisch worden geacht te
                  zijn vervangen door een of meerdere nieuwe bepalingen die rechtsgeldig zijn en zoveel mogelijk
                  aansluiten bij het doel en de strekking van de nietige of vernietigde bepaling. </li>
              </ol>
              <li class="fw-bold">Aanbiedingen en (totstandkoming) Overeenkomst </li>
              <ol class="ps-3">
                <li>
                  Tenzij uitdrukkelijk anders is vermeld, hebben alle offertes van HalloStroom - in welke vorm dan ook –
                  een geldigheidsduur van drie weken en zijn deze mede gebaseerd op de bij de Aanvraag door de Klant
                  verstrekte gegevens. De Klant staat ervoor in bij de Aanvraag juiste gegevens te verstrekken aan
                  HalloStroom.
                </li>
                <li>
                  OffertesbindenHalloStroomniet.EenOvereenkomstkomttotstandopdewijzezoalsomschreven in artikel 3 lid 7.
                </li>
                <li>
                  De Klant kan er in overleg met HalloStroom voor kiezen een Eigen Bijdrage te betalen. HalloStroom kan
                  aanvullende voorwaarden verbinden aan het inwilligen van de Aanvraag en de mogelijkheid om een Eigen
                  Bijdrage te voldoen. HalloStroom kan nooit worden verplicht tot het afgeven van een offerte of het
                  goedkeuren van een Eigen Bijdrage.
                </li>
                <li>
                  Als de Klant, al dan niet samen met een ander, niet het volledige eigendom heeft van de Woning, wordt
                  de Aanvraag niet in behandeling genomen door HalloStroom.
                </li>
                <li>
                  HalloStroom behoudt zich het recht voor op ieder gewenst moment, tot het moment van totstandkoming van
                  de Overeenkomst, een kredietwaardigheidscontrole uit te voeren op de Klant. Op basis van de uitkomst
                  daarvan heeft HalloStroom het recht zonder opgaaf van redenen de Aanvraag niet in behandeling te
                  nemen, dan wel in een later stadium te beslissen niet tot het sluiten van een Overeenkomst over te
                  gaan. </li>
                <li>
                  Als HalloStroom besluit een Aanvraag niet in behandeling te nemen, dan brengt zij de Klant daar
                  schriftelijk van op de hoogte. </li>
                <li>
                  Door akkoord te gaan met een offerte (door te klikken op de akkoord knop in de offerte zelf of door de
                  offerte ondertekend per post of e-mail te retourneren aan HalloStroom), doet de Klant een verzoek bij
                  HalloStroom tot het aangaan van een Overeenkomst. De Overeenkomst komt pas tot stand op het moment dat
                  HalloStroom de order aan de Klant heeft bevestigd. De Klant mag het verzoek, tot het moment waarop de
                  Overeenkomst tot stand komt, zonder opgaaf van redenen annuleren. De Klant dient dit schriftelijk aan
                  HalloStroom kenbaar te maken. </li>
                <li>
                  Als er meerdere eigenaren van de Woning zijn, dan wordt de Overeenkomst met alle eigenaren van de
                  Woning aangegaan. Voor zover de mede-eigena(a)r(en) van de Woning de Overeenkomst niet zelf
                  accorderen, garandeert Klant bevoegd te zijn tot het aangaan van de Overeenkomst namens de
                  mede-eigena(a)r(en). Iedere eigenaar van de Woning is hoofdelijk aansprakelijk voor al hetgeen aan
                  HalloStroom verschuldigd is uit welke hoofde dan ook.
                </li>
              </ol>
              <li class="fw-bold">Werkvoorbereidingsbezoek </li>
              <ol class="ps-3">
                <li>
                  Na totstandkoming van de Overeenkomst kan HalloStroom kosteloos een werkvoorbereidingsbezoek uitvoeren
                  om te controleren of de afspraken in de Overeenkomst haalbaar zijn. Dit kan zowel ter plaatse als
                  telefonisch geschieden. Indien uit het werkvoorbereidingsbezoek blijkt dat de afspraken in de
                  Overeenkomst niet haalbaar zijn doordat sprake is van een van de in artikel 4 lid 2 genoemde
                  situaties, kunnen Partijen ieder afzonderlijk de Overeenkomst binnen zeven dagen na het
                  werkvoorbereidingsbezoek opzeggen.
                </li>
                <li>
                  De afspraken uit de Overeenkomst zijn niet haalbaar indien: </li>
                <ol class="ps-3">
                  <li>
                    Het plaatsen van het Energieproduct niet mogelijk is, bijvoorbeeld omdat de elementen van de Woning
                    die benodigd zijn om het Energieproduct te installeren niet geschikt zijn of een gevaarlijke
                    situatie opleveren; </li>
                  <li>
                    Het Energieproduct in het geheel niet op of in de Woning past of er, in het geval van een
                    zonnestroomsysteem, minder zonnepanelen op de Woning passen dan voorzien;</li>
                  <li>
                    De Klant de bekabeling binnen in de Woning op een wijze of middels een route wenst te realiseren die
                    naar het oordeel van HalloStroom redelijkerwijs niet mogelijk is, niet zijnde de situatie zoals
                    bepaald in artikel 4 lid 3; </li>
                  <li>
                    Er substantieel meerwerk nodig is om het Energieproduct te installeren waarvan de kosten aan de
                    Klant zou moeten worden doorbelast.</li>
                </ol>
                <li>
                  HalloStroom zal de bekabeling zo veel mogelijk via loze leidingen laten lopen. Indien geen loze
                  leidingen aanwezig zijn, zal HalloStroom ofwel de kabel door het trappenhuis laten lopen ofwel via de
                  buitenzijde van de Woning. Deze keuze wordt te allen tijde aan de Klant voorgelegd.
                </li>
              </ol>
              <li class="fw-bold">Herroepen / wijzigen Overeenkomst </li>
              <ol class="ps-3">
                <li>
                  IndiendeKlantmoetwordenaangemerktalsconsumentendeOvereenkomstopafstandofbuiten de verkoopruimte heeft
                  gesloten, heeft de Klant de mogelijkheid de Overeenkomst kosteloos en zonder opgave van redenen te
                  ontbinden gedurende de wettelijke herroepingstermijn van 14 dagen door middel van het invullen en
                  versturen van het formulier op de Website of door middel van een schriftelijke kennisgeving. De
                  bedenktijd gaat in op de dag na de dag waarop de Overeenkomst tot stand is gekomen. Het gevolg van
                  ontbinding is dat het Energieproduct niet door HalloStroom zal worden geïnstalleerd en niet aan de
                  Klant zal worden verhuurd.
                </li>
                <li>
                  HalloStroomisteallentijdegerechtigdomdeOvereenkomstaantepassenindienditredelijkerwijs nodig is om
                  wijzigingen in wet- en regelgeving omtrent desbetreffende Energioeproduct(en) aan te laten sluiten bij
                  de afspraken van Partijen. De Klant zal medewerking verlenen aan de aanpassing van de Overeenkomst
                  tussen Partijen. </li>
              </ol>

              <li class="fw-bold">Huur en eigendom </li>
              <ol class="ps-3">
                <li>
                  Het Energieproduct blijft geheel en te allen tijde in eigendom toebehoren aan HalloStroom. Er zal
                  derhalve geen sprake zijn van natrekking of bestanddeelvorming.
                </li>
                <li>
                  De Klant verklaart hierbij uitdrukkelijk en onherroepelijk afstand te doen van enig eigendomsrecht dat
                  hij kan doen gelden op het Energieproduct en van de stelling dat het Energieproduct tot zijn eigendom
                  behoort, ook na afloop van de Overeenkomst, tenzij de Klant het Energieproduct tijdens of na afloop
                  van de huurperiode van HalloStroom koopt.

                </li>
                <li>
                  Indien ondanks het voorgaande in rechte komt vast te staan dat het Energieproduct eigendom is of kan
                  worden van de eigenaar van de Woning, zal de Klant kosteloos en op redelijke termijn na eerste verzoek
                  van HalloStroom medewerking verlenen aan aanpassing van de Overeenkomst zodat wordt
                  aangesloten bij de bedoeling van Partijen bij het aangaan van de Overeenkomst, meer in het bijzonder
                  dat het eigendom aan HalloStroom blijft of gaat toebehoren.

                </li>
                <li>
                  De Klant verklaart hierbij uitdrukkelijk:
                </li>
                <ol class="ps-3" type="a">
                  <li>
                    Te begrijpen dat HalloStroom het Energieproduct heeft aangeschaft zodat zij het eigendom van het
                    Energieproduct kan blijven behouden;
                  </li>
                  <li>Te kiezen voor een constructie van huur waarin de Klant geen eigenaar is of zal worden van het
                    Energieproduct, tenzij de Klant het Energieproduct van HalloStroom koopt. </li>

                </ol>
                <li>
                  Nadat het Energieproduct is geïnstalleerd of geleverd, wordt de Klant de houder van het
                  Energieproduct. Het Energieproduct blijft nadrukkelijk in eigendom van HalloStroom. HalloStroom heeft
                  het recht het eigendom aan een derde over te dragen. In voorkomend geval deelt HalloStroom de
                  overdracht aan de Klant mede.
                </li>
              </ol>

              <li class="fw-bold">Installatie door HalloStroom </li>
              <ol class="ps-3">
                <li>
                  HalloStroom draagt zorg voor de installatie en/of levering van het Energieproduct volgens de wet- en
                  regelgeving en de door HalloStroom gestelde vereisten. HalloStroom zorgt dat het Energieproduct
                  gemakkelijk kan worden verwijderd en heeft zeggenschap over het voor het Energieproduct te gebruiken
                  materiaal. </li>
                <li>
                  De plaatsing van het Energieproduct is voltooid als deze is geleverd, gemonteerd en functioneert
                  conform de officiele HalloStroom productspecificatie.
                </li>
                <li>
                  HalloStroom kan de opbrengst van het Energieproduct online monitoren en deze opbrengsten aan Klant
                  rapporteren. Deze monitoring en rapportage maken echter geen onderdeel uit van de Overeenkomst en
                  HalloStroom kan hiertoe niet worden verplicht.
                </li>
                <li>
                  HalloStroom behoudt zich het recht voor om:
                </li>
                <ol class="ps-3" type="1">
                  <li>Een ander Energieproduct dan overeengekomen, maar van minimaal vergelijkbare kwaliteit bij de
                    Klant te plaatsen in geval van onvoorziene omstandigheden; </li>
                  <li>De installatie van het Energieproduct op te schorten, indien het op de dag van installatie naar
                    het oordeel van HalloStroom niet mogelijk is om het Energieproduct veilig te installeren, zoals ten
                    gevolge van weersomstandigheden. HalloStroom zal de Klant hier minimaal twee uur voorafgaand aan het
                    afgesproken tijdstip van installatie van op de hoogte stellen via de bij HalloStroom bekende
                    contactgegevens. </li>

                  <li>
                    De installatie van het Energieproduct af te breken en/of de Overeenkomst te ontbinden indien
                    HalloStroom van oordeel is dat: </li>

                  <ul style="list-style-type:disc;">
                    <li>- De veiligheid van de Medewerker(s) in het geding is;</li>
                    <li>- De locatie niet afdoende bereikbaar is, de onderdelen van het Energieproduct deze locatie niet
                      kunnen bereiken en/of de Klant niet voldoende medewerking verleent tijdens de installatie. In
                      dat geval komen alle door HalloStroom gemaakte kosten voor rekening van de Klant;
                    </li>
                    <li>- De Klant niet voldoet aan de verplichtingen uit artikel 9. In dat geval komen alle door
                      HalloStroom gemaakte kosten voor rekening van de Klant;
                    </li>
                    <li>- De Klant onjuiste en/of onvolledige informatie heeft verstrekt over hetgeen voor de
                      installatie
                      van het Energieproduct relevant is. In dat geval komen alle door HalloStroom gemaakte kosten voor
                      rekening van de Klant.
                    </li>
                  </ul>
                </ol>
                <li>Door HalloStroom afgegeven termijnen voor aflevering en installatie zijn altijd indicatief en kunnen
                  niet worden aangemerkt als fatale termijnen. </li>
                <li>Bij een storing of een defect dient de Klant daarvan binnen zeven dagen melding te doen aan
                  HalloStroom per e-mail aan: <a href="mailto:klantenservice@hallostroom.nl">klantenservice@hallostroom.nl</a>. Indien het defect tijdig aan HalloStroom is
                  gemeld, zal HalloStroom het defect zo spoedig als redelijkerwijs mogelijk is verhelpen of een
                  alternatieve oplossing aandragen. Bij vervanging van eventuele onderdelen zorgt HalloStroom voor
                  minimaal een gelijkwaardige kwaliteit die overeenstemt met de dan actuele levensduur van het
                  Energieproduct van de Klant. Indien een melding onterecht is of de Klant niet thuis is op het voor
                  herstel overeengekomen tijdstip, zullen alle door HalloStroom gemaakte kosten voor rekening komen van
                  de Klant. </li>

              </ol>
              <li class="fw-bold">Onderhoud en reparaties </li>
              <ol class="ps-3">
                <li>De door HalloStroom uit te voeren installatie-, onderhouds- en reparatiewerkzaamheden vinden in
                  beginsel plaats op werkdagen van 07.00 uur tot 18.00 uur. Feestdagen zijn hiervan uitgesloten. De
                  Klant kan een afspraak tot zeven kalenderdagen voor de dag waarop deze gepland staat kosteloos
                  verzetten, hierna zijn de door HalloStroom in dat kader gemaakte kosten voor rekening van de Klant.
                </li>
                <li>
                  De kosten voor onderhoud en reparaties komen voor rekening van HalloStroom, tenzij de Klant niet heeft
                  voldaan aan de verplichtingen zoals omschreven in artikel 9 en/of verzegeling van het Energieproduct
                  door of namens de Klant is verbroken alsmede wanneer de reparatiewerkzaamheden moeten worden
                  uitgevoerd buiten de reguliere werktijden. Indien de installatie uitloopt tot na 18.00 uur en deze
                  uitloop aan HalloStroom is te wijten, worden geen extra kosten in rekening gebracht bij de Klant.
                </li>
                <li>
                  In afwijking van het in artikel 8.2 bepaalde zal HalloStroom eventuele lekkage die binnen drie maanden
                  na installatie van het Energieproduct door HalloStroom schriftelijk wordt gemeld en die redelijkerwijs
                  het gevolg kan zijn van installatie, kosteloos verhelpen. Na deze termijn komt herstel van lekkage
                  voor rekening van de Klant, tenzij de Klant kan aantonen dat de lekkage is toe te rekenen aan
                  HalloStroom.
                </li>
                <li>
                  HalloStroom zal het Energieproduct op schriftelijk verzoek van de Klant binnen vier weken na ontvangst
                  van het verzoek tijdelijk verwijderen voor zover dat noodzakelijk is voor onderhoud aan de Woning.
                  HalloStroom informeert de Klant voorafgaand aan de verwijdering over de kosten, welke voor rekening
                  van de Klant zijn. De Huurprijs blijft onverkort verschuldigd.
                </li>
              </ol>
              <li class="fw-bold">Verplichtingen van de Klant </li>
              <ol class="ps-3">
                <li>De Klant is verplicht om op eigen kosten te voldoen aan de hiernavolgende vereisten op het moment
                  van de levering van het Energieproduct:
                </li>
                <ol class=" ps-3" type="a">
                  <li>In het geval van een zonnestroomsysteem, dient Klant te beschikken over een draadloze
                    internetaansluiting en een goed functionerende hoofdmeter zodat de opgewekte energie teruggeleverd
                    kan worden aan het openbare stroomnet;


                  </li>
                  <li>Klant dient ervoor te zorgen dat er geen asbest aanwezig is in en rondom de Woning en dat het deel
                    waar het Energieproduct wordt geplaatst in goede technische staat verkeert;

                  </li>
                  <li>Klant dient zorg te dragen dat er bij de levering en de montage van het Energieproduct een persoon
                    aanwezig is die voldoende gemachtigd is om te verifiëren dat installatie correct heeft
                    plaatsgevonden;

                  </li>
                  <li>Klant dient na te gaan of - en ervoor te zorgen dat - het aanvragen van een vergunning of het
                    verkrijgen van toestemming of goedkeuring van bijvoorbeeld verzekeraars, hypotheekverstrekkers of
                    een vereniging van eigenaren, voor het plaatsen van het Energieproduct succesvol is afgerond;

                  </li>
                  <li>Klant zorgt ervoor dat de Woning niet uitsluitend afhankelijk is van het Energieproduct maar ook
                    een andere elektriciteitsaansluiting heeft zodat deze zonder het Energieproduct compleet en
                    bewoonbaar is.
                  </li>
                </ol>
                <li>
                  De Klant is verplicht om te voldoen aan de hiernavolgende vereisten op het moment dat de installatie
                  van het Energieproduct is voltooid.
                </li>
                <ol class="ps-3">
                  <li>Het verzorgen van regulier onderhoud aan het Energieproduct zoals een goed huisvader betaamt;


                  </li>
                  <li>De gebruiksaanwijzing(en) die door of namens HalloStroom zijn verstrekt op te volgen;

                  </li>
                  <li>Het Energieproduct alléén te gebruiken voor de bestemming waar deze voor geschikt
                    of op toegerust is;


                  </li>
                  <li>Het Energieproduct periodiek en minimaal één keer per zeven dagen te controleren op
                    de werkzaamheid daarvan en eventuele storingen of defecten te melden binnen de in artikel 7 lid 6
                    bepaalde termijn.


                  </li>
                </ol>
                <li>
                  De Klant zal bij alle werkzaamheden met betrekking tot het Energieproduct geen andere partijen
                  inschakelen dan HalloStroom, zoals voor onderhoud, reparaties, aanpassingen of het verplaatsen van het
                  Energieproduct. Ook mag alleen HalloStroom besluiten of aanpassingen aan het Energieproduct nodig
                  zijn. Bij overtreding van dit artikel is de Klant verantwoordelijk voor de schade en/of kosten om het
                  Energieproduct te herstellen of terug te brengen in de originele staat.
                </li>
                <li>
                  De Klant zal het Energieproduct niet vervreemden, onderverhuren, in gebruik of bewaring geven, of een
                  zekerheids- of beperkt recht op het Energieproduct (laten) vestigen zonder voorafgaande schriftelijke
                  toestemming van HalloStroom.
                </li>
              </ol>
              <li class="fw-bold">
                Vergoedingen en betaling
              </li>
              <ol class="ps-3">
                <li>De Klant is de Huurprijs verschuldigd voor de gehele duur van de Overeenkomst. De Klant ontvangt
                  rond iedere 21e van de maand een factuur voor de daaropvolgende maand, welke binnen zeven dagen na
                  factuurdatum dient te worden voldaan. De factuur van de eerste maand omvat de periode vanaf de
                  installatiedatum tot het einde van de maand en de volgende maand. De Klant betaalt de Huurprijs dus
                  per maand vooruit. De betalingstermijn geldt als een fatale termijn als bedoeld in artikel 6:83 sub a
                  van het Burgerlijk Wetboek.

                </li>
                <li>Indien de overheid belasting heft op het Energieproduct en/of er kosten gemaakt worden die op basis
                  van de Overeenkomst of deze Algemene Voorwaarden voor rekening van de Klant dienen te komen, zullen
                  deze worden doorberekend aan de Klant op de eerstvolgende factuur.
                </li>
                <li>Indien de Klant met HalloStroom is overeengekomen een Eigen Bijdrage te voldoen, zal deze worden
                  opgenomen op de eerste factuur.

                </li>
                <li>Alle aan HalloStroom op grond van de Overeenkomst en deze Algemene Voorwaarden toekomende bedragen
                  worden maandelijks middels doorlopende SEPA automatische incasso voldaan. De automatische incasso zal
                  plaatsvinden binnen de in lid 1 van dit artikel genoemde betaaltermijn. Wordt de automatische incasso
                  gestorneerd en vindt betaling niet alsnog (op eigen initiatief van de Klant) binnen de
                  betalingstermijn plaats, dan komt de Klant van rechtswege in verzuim te verkeren.


                </li>
                <li>Bij een stornering van de SEPA-incasso zal HalloStroom de Klant schriftelijk aanmanen en de Klant
                  een termijn van 14 dagen geven om de betreffende factuur alsnog te voldoen. Als de Klant binnen de
                  genoemde termijn niet alsnog heeft betaald, zal HalloStroom een laatste termijn van 14 dagen geven om
                  de betreffende factuur alsnog te voldoen. HalloStroom behoudt zich het recht voor om eventuele kosten
                  van stornering aan de Klant door te belasten indien blijkt dat stornering niet op rechtmatige
                  juridische grondslag berust.

                </li>
                <li>De door HalloStroom– na het moment van aanmaning als bedoeld in lid 4 van dit artikel – gemaakte
                  kosten ter verkrijging van voldoening buiten rechte in geval van niet of niet tijdige betaling door de
                  Klant komen voor rekening van de Klant, conform de staffel buitengerechtelijke incassokosten (op grond
                  van de Wet normering buitengerechtelijke incassokosten en het bijbehorende Besluit), met een minimum
                  van EUR 40,-.
                </li>
                <li>Het niet (kunnen) gebruiken van het Energieproduct levert geen gegronde reden voor geen of niet
                  tijdige betaling.

                </li>
                <li>De resterende huurbedragen zijn direct opeisbaar indien de Klant drie of meer huurtermijnen niet
                  heeft voldaan en/of verzoekt te worden toegelaten tot de gerechtelijke schuldsanering, in geval van
                  faillissement van de Klant, alsmede bij surseance van betaling of beslag ten laste van de Klant. De
                  Klant zal te allen tijde aangeven dat het eigendom van het Energieproduct bij HalloStroom ligt, op
                  straffe van verwijdering van het Energieproduct door HalloStroom.
                </li>
              </ol>

              <li class="fw-bold">
                Verhuizing, verkoop en koop
              </li>
              <ol class="ps-3">
                <li>Bij een verhuizing is de Klant verplicht om HalloStroom hier onverwijld schriftelijk over te
                  informeren. Indien de Woning wordt verkocht, is de Klant verplicht om HalloStroom daar schriftelijk
                  over te informeren en door middel van bewijsstukken aan te tonen dat de Woning daadwerkelijk is
                  verkocht. Klant is tevens verplicht de (potentiele) koper van de Woning te informeren over het
                  Energieproduct. Bij verhuizing of verkoop kan de Klant ervoor kiezen om:

                </li>
                <ol class="ps-3">
                  <li>Het Energieproduct mee te verhuizen naar de nieuwe Woning mits deze naar oordeel van HalloStroom
                    geschikt is om mee te verhuizen naar de nieuwe Woning en het Energieproduct op of in de nieuwe
                    Woning past. De Klant is hiervoor een van de nieuwe woonplaats afhankelijke vergoeding verschuldigd
                    en kan alleen gebruik maken van deze mogelijkheid indien de Klant binnen Nederland verhuist;
                  </li>
                  <li>De Overeenkomst voor de resterende looptijd te laten overnemen door de nieuwe eigenaar van de
                    Woning. Hiervoor is vereist dat de Overeenkomst volledig ingevuld en ondertekend door de nieuwe
                    eigenaar is ontvangen door HalloStroom, voordat de Woning is overgedragen. De Klant is verplicht te
                    bewijzen dat degene die de Overeenkomst overneemt, daadwerkelijk de nieuwe eigenaar van de Woning
                    wordt. HalloStroom kan een kredietwaardigheidscontrole uitvoeren ten aanzien van de nieuwe eigenaar
                    van de Woning alvorens de overname van de Overeenkomst goed te keuren.</li>
                </ol>

                <li>Indien het onder a) en b) bepaalde niet mogelijk is, kan HalloStroom het Energieproduct verwijderen
                  tegen een vergoeding. De hoogte van deze vergoeding zal op verzoek van de Klant worden begroot. De
                  resterende Huurprijzen zullen ineens en per direct in rekening worden gebracht.
                </li>
                <li>De Klant heeft de mogelijkheid om het Energieproduct van HalloStroom over te kopen voor de dan
                  geldende marktwaarde, zowel tijdens als één maand na afloop van de Overeenkomst. Indien de Klant het
                  Energieproduct gedurende de looptijd van de Overeenkomst over wenst te nemen en bereid is de hiervoor
                  genoemde prijs te betalen, zal de Overeenkomst met wederzijds goedvinden tussentijds worden
                  beëindigd. Koop van het Energieproduct door Klant heeft pas effect indien HalloStroom daar
                  uitdrukkelijk en schriftelijk mee akkoord gaat. Klant kan aan deze bepaling in artikel 11 lid 3 geen
                  enkel recht ontlenen.
                </li>
                <li>Nadat het Energieproduct door de Klant is gekocht, vervalt de Overeenkomst. De Klant kan in geval
                  van een defect aan het Energieproduct contact opnemen met de fabrikant daarvan. Deze zal dan
                  beoordelen of het gemelde gebrek al dan niet (nog) onder de fabrieksgarantie valt.
                </li>
              </ol>


              <li class="fw-bold">
                Aansprakelijkheid
              </li>
              <ol class="ps-3">
                <li>HalloStroom zal uitsluitend aansprakelijk zijn voor toerekenbare tekortkomingen. HalloStroom heeft
                  een bedrijfsaansprakelijkheidsverzekering afgesloten die een veelomvattende dekking biedt voor de
                  risico’s die zich in het kader van haar bedrijfsactiviteiten kunnen voordoen. HalloStroom is slechts
                  aansprakelijk voor een bedrag ter hoogte van het daadwerkelijk door haar verzekering uitgekeerde
                  bedrag voor het betreffende schadegeval. In het geval de verzekering onverhoopt niet uitkeert, is de
                  aansprakelijkheid van HalloStroom beperkt tot maximaal een bedrag van EUR 10.000.

                </li>
                <li>HalloStroom is nimmer aansprakelijk voor gevolgschade (indirecte schade) van de Klant. Onder
                  gevolgschade wordt onder meer verstaan: gemaakte kosten en gemiste besparingen.
                </li>
                <li>De Klant neemt alle noodzakelijke maatregelen ter voorkoming of beperking van schade.
                </li>
                <li>OndanksdezorgenaandachtdieHalloStroomaandesamenstellingvandeverstrekteinformatie heeft besteed, is
                  het mogelijk dat informatie die in welke vorm dan ook door HalloStroom openbaar wordt gemaakt
                  onvolledig en/of onjuist is. HalloStroom is niet aansprakelijk voor eventuele schade die daarvan het
                  gevolg is.

                </li>
                <li>HalloStroom is niet aansprakelijk voor schade ontstaan als gevolg van:
                </li>
                <ol class="ps-3">
                  <li>Ondeskundig gebruik of gebruik in strijd met het doel van het Energieproduct, gebruik in strijd
                    met de door/namens HalloStroom verstrekte instructies, adviezen, gebruiksaanwijzingen,
                    productinformatie e.d. en/of relevante wetgeving;
                  </li>
                  <li>Het tekortschieten van de Klant in zijn verplichtingen uit de Overeenkomst en/of de Algemene
                    Voorwaarden, waaronder in het bijzonder dient te worden begrepen het niet voldoen van de Klant aan
                    het bepaalde in artikel 9 van de Algemene Voorwaarden;</li>
                  <li>Fouten en/of onvolledigheden in de door of namens de Klant verstrekte informatie;</li>
                  <li>Bewerkingen die door de Klant of namens hem aan het Energieproduct zijn uitgevoerd
                    of daaraan zijn toegevoegd.
                  </li>
                </ol>

                <li>De Klant is in de gevallen als opgesomd in artikel 12 lid 5 volledig aansprakelijk voor eventueel
                  hieruit voortvloeiende schade van HalloStroom en vrijwaart HalloStroom voor alle eventuele aanspraken
                  van derden.
                </li>
                <li>In afwijking van het voorgaande heeft de Klant, indien HalloStroom 30 dagen na melding van een
                  storing of defect zoals bepaald in artikel 7 lid 6 nog niet tot herstel is overgegaan, uitsluitend
                  recht op de in dit artikel beschreven schadevergoeding. De schadevergoeding omvat de vergoeding van de
                  gemiste opbrengst (kWp). Deze wordt berekend als volgt: (aantal niet functionerende zonnepanelen x
                  vermogen (Wp) per niet functionerend paneel) / 1000. Als de gemiste opbrengst (kWp) door deze
                  berekening is vastgesteld, gelden de volgende vergoedingen:
                </li>
                <ol type="a" class="ps-3">
                  <li>€ 0,80 per kWp per dag in de periode van 1 mei tot en met 31 augustus b. € 0,40 per kWp per dag in
                    de periode van 1 september tot en met 30 april </li>
                </ol>
                <li>HalloStroom bedingt alle wettelijke en contractuele verweermiddelen die zij ter afwering van haar
                  eigen aansprakelijkheid ten opzichte van de Klant kan inroepen, mede ten behoeve van haar
                  ondergeschikten en de niet ondergeschikten voor wiens gedragingen zij op grond van de wet
                  aansprakelijk is.

                </li>
                <li>De in dit artikel opgenomen beperkingen van aansprakelijkheid gelden niet als de schade te wijten is
                  aan opzet en/of bewuste roekeloosheid van HalloStroom of het leidinggevend personeel of als
                  dwingendrechtelijke wettelijke bepalingen zich hiertegen verzetten. Uitsluitend in deze gevallen zal
                  HalloStroom de Klant vrijwaren voor eventuele aanspraken van derden.
                </li>
              </ol>
              <li class="fw-bold">
                Saldering
              </li>
              <ol class="ps-3">
                <li>In het geval van een zonnestroomsysteem wordt op grond van huidige wet- en regelgeving de met het
                  zonnestroomsysteem opgewekte energie door de energieleverancier van de Klant op de jaarafrekening met
                  de hoeveelheid verbruikte energie gesaldeerd. Indien de Klant meer energie heeft verbruikt dan
                  opgewekt, brengt de energieleverancier het meerdere bij de Klant in rekening.

                </li>
                <li>Een eventuele wijziging van deze wet- en regelgeving die tot gevolg heeft dat saldering niet meer
                  mogelijk is, biedt voor de Klant geen grondslag de Overeenkomst te ontbinden en/of eventuele daardoor
                  geleden schade op HalloStroom te verhalen.
                </li>
              </ol>
              <li class="fw-bold">
                Intellectuele eigendomsrechten
              </li>
              <ol class="ps-3">
                <li>De Klant erkent uitdrukkelijk dat alle rechten van intellectuele eigendom van teksten, afbeeldingen
                  en tekeningen bij of op het Energieproduct, op de HalloStroom website en/of in catalogussen,
                  brochures, prijslijsten, promotiemateriaal of andersoortige documenten die door HalloStroom worden
                  verstrekt berusten en blijven berusten bij HalloStroom, haar toeleverancier(s) of andere
                  rechthebbenden. Hetzelfde geldt voor het in het kader van de Overeenkomst geleverde Energieproduct
                  zelf en de door HalloStroom gedane aanbiedingen en orderbevestigingen.
                </li>
                <li>Zonder uitdrukkelijk voorafgaande schriftelijke toestemming van HalloStroom, haar toeleverancier(s)
                  of andere rechthebbenden is het de Klant verboden
                  gebruik te maken, daaronder begrepen het aanbrengen van wijzigingen en het maken van kopieën, van de
                  intellectuele eigendomsrechten zoals beschreven in artikel 14 lid 1.
                </li>
                <li>Het is de Klant voorts niet toegestaan enige aanduiding van auteursrechten, merken, handelsnamen of
                  andere rechten van intellectuele eigendom uit of van de in artikel 14 lid 1 genoemde zaken, waaronder
                  verpakkingen en handleidingen, te verwijderen of te wijzigen.</li>
              </ol>
              <li class="fw-bold">
                Duur en beëindiging 
              </li>
              <ol class="ps-3">
                <li>De Overeenkomst wordt aangegaan voor de duur zoals overeengekomen in de Overeenkomst. Tussentijdse opzegging van de Overeenkomst is niet mogelijk, tenzij anders is bepaald in deze Algemene Voorwaarden.
                </li>
                <li>Onverminderd haar wettelijke rechten heeft HalloStroom het recht de Overeenkomst met onmiddellijke ingang en zonder gerechtelijke tussenkomst schriftelijk te beëindigen in geval van (een aanvraag voor) surseance van betaling, (een aanvraag voor) faillissement of (een verzoek tot) toelating tot gerechtelijke schuldsanering door of ten aanzien van de Klant
                </li>
                <li>Partijen hebben het recht de Overeenkomst met onmiddellijke ingang en zonder gerechtelijke tussenkomst middels een aangetekende brief te ontbinden indien de andere Partij haar verplichtingen uit hoofde van de Overeenkomst en/of de Algemene Voorwaarden niet nakomt, en deze Partij, ná ingebrekestelling bij aangetekend schrijven door de andere Partij niet binnen de in de ingebrekestelling gestelde redelijke termijn alsnog aan haar verplichtingen voldoet. </li>
                <li>In geval van overlijden van (één of meer van de) de Klant(en) wordt de Overeenkomst voortgezet met de andere Klant(en) en/of de erfgenamen van Klant.</li>
                  
                <li>BijbeëindigingvandeOvereenkomst,ongeachtdereden,zaldeKlanthetEnergieproductbinnen 24 uur na afloop daarvan in goede staat aan HalloStroom ter beschikking stellen en alle redelijke medewerking verlenen aan HalloStroom opdat zij zorg kan dragen voor de demontage en retournering van het Energieproduct. Voornoemde is niet van toepassing op de situatie als beschreven in artikelen 11 lid 1 en 11 lid 3 van deze Algemene Voorwaarden.
                </li>
                <li>De kosten voor het verwijderen van het Energieproduct na afloop van de Overeenkomst komen voor rekening van HalloStroom, tenzij de Overeenkomst tussentijds wordt opgezegd of sprake is van de situaties zoals bepaald in artikelen 8 lid 4, 11 lid 1 en 11 lid 3 van deze Algemene Voorwaarden. HalloStroom is niet gehouden de Woning terug te brengen naar de originele staat van vóór plaatsing van het Energieproduct, tenzij ontstane schade het gevolg is van opzet en/of bewuste roekeloosheid van een Medewerker. 
                </li>
              </ol>
              
              <li class="fw-bold">
                Overige bepalingen 
              </li>
              <p>HalloStroom zal de (persoons)gegevens van de Klant uitsluitend verwerken in overeenstemming met haar privacy beleid. Dit beleid is in overeenstemming met van toepassing zijnde wet- en regelgeving en is vastgelegd in een privacy statement dat op de Website gepubliceerd is. 
<br/>HalloStroom heeft het recht haar rechten en verplichtingen uit de Overeenkomst over te dragen aan een derde. De Klant geeft daar hierbij op voorhand toestemming voor. De Klant heeft, in geval van overdracht van de rechten en verplichtingen uit de Overeenkomst door HalloStroom aan een derde, het recht binnen drie maanden nadat de Klant van deze overdracht schriftelijk door HalloStroom op de hoogte is gebracht de Overeenkomst door middel van een schriftelijke verklaring en zonder gerechtelijke tussenkomst te ontbinden. 
</p>
<li class="fw-bold">
  Toepasselijk recht en geschillen
              </li>
              <p>Op de rechtsverhoudingen tussen Partijen, van welke aard dan ook, is Nederlands recht van toepassing. 
<br/>De bevoegde rechter van de Rechtbank Den Haag is bevoegd om van alle geschillen tussen Partijen kennis te nemen, tenzij dit in strijd is met dwingend recht. HalloStroom mag van deze forumkeuze afwijken en de wettelijke bevoegdheidsregels hanteren.
</p>
            </ol>
            </p>
          </div>
          <div class="content_box">
            <h4>Contact </h4>
            <p>
              HalloStroom Markt & Klant B.V. Bouwmeesterplein 1
              <br/>
2801BX Gouda<br/>
Telefoonnummer: <a href="tel:0307670078">030 767 00 78</a> E-mail: <a href="mailto:klantenservice@hallostroom.nl">klantenservice@hallostroom.nl</a> KvK-nummer: 71180907

            </p>
          </div>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  color: #C1C1C1;

}

.underline {
  text-decoration: underline;
}
</style>
