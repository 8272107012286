<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <!-- <BackButton @back="goBack" /> -->
      <p class="mb-0 vraag">Vraag {{ step }} van 5</p>
      <h2 class="title">Uit hoeveel personen bestaat jouw huishouden?</h2>
      <div class="row my-4 mx-0">
        <b-form-group>
          <b-form-radio
            v-for="(item, index) of options"
            v-model="selected"
            :key="index"
            :name="'dateOption'"
            :value="item.value"
            @change="updateDiv(item.value)"
          >
            {{ item.title }}
          </b-form-radio>
        </b-form-group>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue";
export default {
  name: "Question5",
  components: { BackButton },
  data() {
    return {
      selected: -1,
      options: [
        { title: "1 persoon (+/- 2.300 kwh)", value: "5026" },
        { title: "2 personen (+/- 3.400 kwh)", value: "5029" },
        { title: "3 personen (+/- 4.100 kwh)", value: "5032" },
        { title: "4 personen (+/- 4.600 kwh)", value: "5035" },
        { title: "Meer dan 4 personen", value: "5038" },
      ],
    };
  },
  props: {
    step: {
      type: Number,
    },
    fifthQuestion: {
      type: String,
    },
  },
  mounted() {
    if (this.fifthQuestion) {
      this.selected = this.fifthQuestion;
    }
  },
  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(value) {
      this.$emit("handleChange", "fifthQuestion", value);
      setTimeout(() => {
        this.goToNextStep();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
