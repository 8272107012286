import { render, staticRenderFns } from "./OverHollostroom.vue?vue&type=template&id=faa1bd62&scoped=true"
import script from "./OverHollostroom.vue?vue&type=script&lang=js"
export * from "./OverHollostroom.vue?vue&type=script&lang=js"
import style0 from "./OverHollostroom.vue?vue&type=style&index=0&id=faa1bd62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faa1bd62",
  null
  
)

export default component.exports