<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <!-- <BackButton @back="goBack" /> -->
      <p class="mb-0 vraag">Vraag {{ step }} van 5</p>
      <h2 class="title">Wat voor soort dak heeft jouw woning?</h2>
      <div class="row my-4 mx-0">
        <div
          class="col-6 col-sm-4 p-0"
          v-for="index in ['5014', '5017']"
          :key="index"
        >
          <div
            :class="{
              'input__form-div-selected': selected == index,
              'input__form-div': selected != index,
            }"
            @click="updateDiv(index)"
          >
            <div v-if="index == '5014'">
              <svg
                width="85"
                height="70"
                viewBox="0 0 85 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M84.0618 35.6984L78.1243 14.9172C77.9447 14.275 77.5542 13.712 77.0157 13.3189C76.4771 12.9258 75.8219 12.7254 75.1556 12.75H69.2181V3.84375C69.2181 3.05639 68.9053 2.30128 68.3485 1.74453C67.7918 1.18778 67.0367 0.875 66.2493 0.875H54.3743C53.5869 0.875 52.8318 1.18778 52.2751 1.74453C51.7183 2.30128 51.4056 3.05639 51.4056 3.84375V12.75H9.84305C9.17673 12.7254 8.52151 12.9258 7.98295 13.3189C7.4444 13.712 7.05388 14.275 6.8743 14.9172L0.936804 35.6984C0.812586 36.1365 0.790819 36.5974 0.873185 37.0452C0.955551 37.4931 1.13984 37.916 1.4118 38.2812C1.70012 38.6665 2.07775 38.9758 2.51217 39.1827C2.94658 39.3895 3.42477 39.4877 3.90555 39.4688H6.8743V66.1875C6.8743 66.9749 7.18708 67.73 7.74383 68.2867C8.30058 68.8435 9.05569 69.1562 9.84305 69.1562H75.1556C75.9429 69.1562 76.698 68.8435 77.2548 68.2867C77.8115 67.73 78.1243 66.9749 78.1243 66.1875V39.4688H81.0931C81.5539 39.4688 82.0085 39.3614 82.4207 39.1553C82.8329 38.9492 83.1915 38.65 83.4681 38.2812C83.7603 37.9261 83.9661 37.508 84.0691 37.0598C84.1722 36.6116 84.1697 36.1455 84.0618 35.6984ZM57.3431 6.8125H63.2806V12.75H57.3431V6.8125ZM12.0696 18.6875H72.929L77.1446 33.5312H7.85399L12.0696 18.6875ZM42.4993 63.2188H24.6868V48.375H42.4993V63.2188ZM72.1868 63.2188H48.4368V45.4062C48.4368 44.6189 48.124 43.8638 47.5673 43.307C47.0105 42.7503 46.2554 42.4375 45.4681 42.4375H21.7181C20.9307 42.4375 20.1756 42.7503 19.6188 43.307C19.0621 43.8638 18.7493 44.6189 18.7493 45.4062V63.2188H12.8118V39.4688H72.1868V63.2188Z"
                  fill="black"
                />
                <path
                  d="M54.375 60.25H66.25C67.0374 60.25 67.7925 59.9372 68.3492 59.3805C68.906 58.8237 69.2188 58.0686 69.2188 57.2812V45.4062C69.2188 44.6189 68.906 43.8638 68.3492 43.307C67.7925 42.7503 67.0374 42.4375 66.25 42.4375H54.375C53.5876 42.4375 52.8325 42.7503 52.2758 43.307C51.719 43.8638 51.4062 44.6189 51.4062 45.4062V57.2812C51.4062 58.0686 51.719 58.8237 52.2758 59.3805C52.8325 59.9372 53.5876 60.25 54.375 60.25ZM57.3438 48.375H63.2812V54.3125H57.3438V48.375Z"
                  fill="black"
                />
              </svg>

              <p class="m-0">Schuin</p>
            </div>
            <div v-else>
              <svg
                width="85"
                height="58"
                viewBox="0 0 85 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M84.0625 21.5627C84.061 21.2571 84.0005 20.9547 83.8844 20.6721C83.8882 20.6028 83.8882 20.5335 83.8844 20.4642L74.9781 2.65174C74.7563 2.09462 74.3709 1.61775 73.8728 1.28389C73.3747 0.950042 72.7871 0.774865 72.1875 0.781428H12.8125C12.2643 0.78309 11.7272 0.936526 11.2608 1.22473C10.7945 1.51294 10.417 1.92465 10.1703 2.41424L1.26406 20.2267C1.26027 20.296 1.26027 20.3653 1.26406 20.4346C1.06248 20.7773 0.950195 21.1652 0.9375 21.5627V54.2189C0.9375 55.0063 1.25028 55.7614 1.80703 56.3181C2.36378 56.8749 3.11889 57.1877 3.90625 57.1877H81.0938C81.8811 57.1877 82.6362 56.8749 83.193 56.3181C83.7497 55.7614 84.0625 55.0063 84.0625 54.2189V21.5627ZM78.125 42.3439H72.1875V30.4689C72.1875 29.6816 71.8747 28.9265 71.318 28.3697C70.7612 27.813 70.0061 27.5002 69.2188 27.5002H51.4062C50.6189 27.5002 49.8638 27.813 49.307 28.3697C48.7503 28.9265 48.4375 29.6816 48.4375 30.4689V42.3439H39.5312V30.4689C39.5312 29.6816 39.2185 28.9265 38.6617 28.3697C38.105 27.813 37.3499 27.5002 36.5625 27.5002H12.8125C12.0251 27.5002 11.27 27.813 10.7133 28.3697C10.1565 28.9265 9.84375 29.6816 9.84375 30.4689V42.3439H6.875V24.5314H78.125V42.3439ZM33.5938 42.3439H15.7812V33.4377H33.5938V42.3439ZM14.6531 6.71893H70.3469L76.2844 18.5939H8.71562L14.6531 6.71893ZM6.875 48.2814H48.4375V51.2502H6.875V48.2814ZM54.375 51.2502V33.4377H66.25V51.2502H54.375ZM78.125 51.2502H72.1875V48.2814H78.125V51.2502Z"
                  fill="black"
                />
              </svg>

              <p class="m-0">Plat</p>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue";
export default {
  name: "Question4",
  components: { BackButton },
  data() {
    return {
      selected: -1,
    };
  },
  props: {
    step: {
      type: Number,
    },
    fourthQuestion: {
      type: String,
    },
  },
  mounted() {
    if (this.fourthQuestion) {
      this.selected = this.fourthQuestion;
    }
  },

  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(index) {
      this.selected = index;
      this.$emit("handleChange", "fourthQuestion", index);
      setTimeout(() => {
        this.goToNextStep();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
