<template>
  <b-container class="py-0">
    <b-row noGutters>
      <div
        class="header w-100 text-start d-flex align-items-center px-md-2 px-0"
      >
        <a
          href="/"
          class="text-decoration-none d-lg-flex align-items-center position-relative"
          ><img src="../assets/brand-logo.svg" class="pe-3 header__logo" />
          <h5 class="text-white h5 mb-0">i.s.m. Mijn Consument</h5></a
        >
        <!-- <div class="d-lg-flex align-items-center">
          <h5 class="text-white h5 mb-0">i.s.m. Mijn Consument</h5> -->
        <img
          src="../assets/navbarframe.svg"
          class="ms-auto d-lg-flex d-none frame"
        />
        <img
          src="../assets/navbarframe_sm.svg"
          class="ms-auto d-lg-none d-flex frame_sm"
        />
        <!-- </div> -->
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.header {
  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__logo {
    width: 300px;
    @media (max-width: 991.98px) {
      width: 220px;
    }
    @media (max-width: 767.98px) {
      width: 150px;
    }
  }
  .h5 {
    font-size: 20px;
    @media (max-width: 991.98px) {
      font-size: 13px;
      position: absolute;
      bottom: 0px;
      right: -25px;
    }
    @media (max-width: 767.98px) {
      font-size: 11px;
      bottom: -3px;
      right: -35px;
    }
  }
  .frame_sm {
    width: 110px;
    @media (max-width: 767.98px) {
      width: 75px;
    }
  }
}
</style>
