<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center align-items-center map-section">
      <!-- <iframe width="100%" height="400" frameborder="0" style="border: 0" allowfullscreen=""
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCIUep3Zbrrok-Wm4Nsj_nwgWKZXIVUjBo&q=42.6042827,-6.4901194&zoom=17"
        loading="lazy">
      </iframe> -->

      <iframe
        width="100%"
        height="400"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        :src="mapUrl"
        loading="lazy"
      >
      </iframe>

      <!-- <GmapMap :options="{
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        disableDefaultUi: false,
      }" :center="{
        lat: this.lat ? this.lat : 0,
        lng: this.lng ? this.lng : 0,
      }" :zoom="18" map-type-id="hybrid" style="width: 100%; height: 100%; border-radius: 100px">
        <GmapMarker :position="{
        lat: this.lat ? this.lat : 0,
        lng: this.lng ? this.lng : 0,
      }" :clickable="true" :draggable="true" />
      </GmapMap> -->
      <!-- <div class="d-flex justify-content-center align-items-center question-card__right-col-text position-absolute">
      </div> -->
    </div>
  </div>
</template>
<script>
import { StreetViewPanorama } from "vue2-google-maps";
import { mapGetters } from "vuex";
export default {
  name: "GoogleMapSection",
  data() {
    return {
      center: { lat: 37.7749, lng: -122.4194 },
      zoom: 12,
    };
  },
  components: {
    StreetViewPanorama,
  },
  computed: {
    ...mapGetters(["lat", "lng", "address"]),
    mapUrl() {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCIUep3Zbrrok-Wm4Nsj_nwgWKZXIVUjBo&q=${this.lat},${this.lng}&zoom=17`;
    },
  },
};
</script>
<style lang="scss" scoped>
.map-section {
  height: 260px !important;
  width: 100% !important;
  //   border: 5px solid red !important;
  border-radius: 15px !important;
  overflow: hidden !important;
  // @media (max-width: 768px) {
  //   height: 83px !important;
  // }
}
</style>
